/* eslint-disable max-len */
import { ageCategoriesKeyLabelTooltip } from 'graphql/participants/models';

const fr = {
  entities: {
    study: {
      study_code: "Code de l'étude",
      study: 'Étude',
      studies: 'Études',
      studyAuto: '{count, plural, =0 {Étude} =1 {Étude} other {Études}}',
      access_limitations: 'Restrictions d’accès',
      access_limitation: "Restriction d'accès",
      access_requirements: 'Conditions d’utilisation',
      access_requirement: "Exigence d'accès",
      access_authority: "Autorité d'accès",
      domain: 'Domaine',
      population: 'Population',
      data_access_codes: {
        access_limitations: 'Restriction d’accès',
        access_requirements: 'Exigence d’accès',
      },
      statistic: 'Statistique sommaire',
      statistics: 'Statistiques sommaires',
      data_access: 'Accès aux données',
      name: 'Nom',
      keywords: 'Mots-clés',
      description: 'Description',
      restrictedTitle: 'Étude restreinte',
      restrictedContent: "Les données de cette étude ne sont pas accessibles à l'exploration.",
      restrictedContact:
        "Les documents de demande d'accès ne peuvent pas être téléchargés pour cette étude. Pour poursuivre votre demande d'accès, veuillez contacter directement l'Autorité d'accès.",
      restrictedContact2:
        "Vous pouvez trouver les coordonnées de l'Autorité d'accès dans le tableau d'accès aux données sur la page des études.",
      CQDGStudies: 'Études du CQDG',
    },
    biospecimen: {
      sample_id: `ID échantillon`,
      submitter_sample_id: `ID échantillon externe`,
      sample: 'Échantillon',
      samples: 'Échantillons',
      samplesAuto: '{count, plural, =0 {Échantillon} =1 {Échantillon} other {Échantillons}}',
      biospecimensAuto: '{count, plural, =0 {Biospécimen} =1 {Biospécimen} other {Biospécimens}}',
      biospecimen: 'Biospécimen',
      biospecimens: 'Biospécimens',
      sample_type: "Type d'échantillon",
      biospecimen_tissue_source: 'Tissue',
      biospecimen_id: 'ID biospécimen',
      age: 'Âge',
      age_biospecimen_collection: 'Âge au prélèvement du biospécimen',
    },
    participant: {
      participant_id: 'ID participant',
      participant_2_id: 'ID participant',
      submitter_participant_id: 'ID participant externe',
      participant: 'Participant',
      participants: 'Participants',
      id: 'Identifiant',
      participantAuto: '{count, plural, =0 {Participant} =1 {Participant} other {Participants}}',
      participantsSamples: 'Participants / Échantillons',
      participantSample: 'Participant / Échantillon',
      profile: 'Profil',
      family: 'Famille',
      families: 'Familles',
      diagnosis: 'Diagnostic',
      diagnoses: 'Diagnostics',
      diagnosis_mondo: 'Diagnostic (MONDO)',
      diagnosis_icd10: 'Diagnostic (ICD-10)',
      diagnosis_icd: 'Diagnostic (ICD)',
      diagnosis_source_text: 'Diagnostic (texte source)',
      age_at_diagnosis: 'Âge au diagnostic',
      age_at_phenotype: 'Âge au phénotype du biospécimen',
      phenotype: 'Phénotype',
      phenotypes: 'Phénotypes',
      phenotype_hpo: 'Phénotype (HPO)',
      family_type: 'Type de famille',
      family_position: 'Position familiale',
      family_id: 'ID de famille',
      sex: 'Sexe',
      demographic: 'Démographie',
      population: 'Population',
      ethnicity: 'Ethnicité',
      age: 'Âge',
      age_at_recruitment: 'Âge au recrutement',
      vital_status: 'Statut vital',
      age_at_outcome: 'Âge au résultat',
      age_of_death: 'Âge au décès',
      cause_of_death: 'Cause du décès',
      disease_status: 'Statut clinique',
      cancer: 'Cancer',
      mondo_term: 'Terme MONDO',
      mondo_term_tooltip: '# de participants avec le terme MONDO exact',
      phenotype_code: 'Phénotype (HPO)',
      phenotype_source_text: 'Phénotype (texte source)',
      interpretation: 'Interprétation',
      hpo_term: 'Terme HPO',
      hpo_term_tooltip: '# de participants avec le terme HPO exact',
      observed: 'Observé',
      not_observed: 'Non observé',
      participantsBySex: 'Participants par sexe',
      participantsByEthnicity: 'Participants par ethnicité',
      participantsByStudy: 'Participants par étude',
      participantsByDataType: 'Participants par type de données',
      participantsByStrategy: 'Participants par stratégie',
      participantsByPopulation: 'Participants par population',
      participantsByDomain: 'Participants par domaine',
    },
    file: {
      file: 'Fichier',
      files: 'Fichiers',
      datafile: 'Fichier de données',
      datafiles: 'Fichiers',
      filesAuto: '{count, plural, =0 {Fichier} =1 {Fichier} other {Fichiers}}',
      identifiant: 'Identifiant',
      file_id: 'ID fichier',
      file_2_id: 'ID fichier',
      name: 'Nom',
      file_name: 'Nom du fichier',
      file_format: 'Format',
      file_size: 'Taille',
      ferload_url: 'URL',
      file_hash: 'Hash',
      participants: '{count, plural, =0 {Participant} =1 {Participant} other {Participants}}',
      samples: '{count, plural, =0 {Échantillon} =1 {Échantillon} other {Échantillons}}',
      analysis: 'Analyse',
      type: 'Type',
      data_type: 'Type de données',
      data_type_info: 'Comprendre les types de données et la méthodologie du CQDG.',
      file_authorization: 'Autorisation de fichier',
      fileReadMore: "Demande d'accès aux données",
      locked:
        "Vous n'êtes pas autorisé à accéder à ce fichier. Pour plus de détails, consultez la page ",
      unlocked: 'Vous avez accès à ce fichier.',
      controlled: 'Contrôlé',
      authorized: 'Autorisé',
      unauthorized: 'Non autorisé',
      registered: 'Enregistré',
      category: 'Catégorie',
      data_category: 'Catégorie de données',
      data_access: 'Accès aux données',
      specialized_datasets: 'Jeux de données spécialisés',
      datasetInfo:
        'Les jeux de données spécialisés suivants sont offerts par l’étude aux chercheurs qui en font la demande. Un jeu de données spécialisé est un sous-ensemble des données de l’étude qui fait référence à une publication, un résultat d’une analyse, un événement de collecte ou à tout autre particularité que l’étude veut mettre en évidence.',
      dataset: 'Jeux de données',
      experimentalProcedure: 'Procédure expérimentale',
      analysisProperties: 'Propriétés de l’analyse',
      strategy: 'Stratégie',
      sequencing_experiment: {
        experimental_strategy: 'Stratégie expérimentale',
        type_of_sequencing: 'Type de séquençage',
        read_length: 'Longueur des fragments',
        platform: 'Plateforme',
        capture_kit: 'Kit de capture',
        sequencer_id: 'Séquenceur',
        run_date: 'Date (aaaa-mm-jj)',
        run_name: 'Run',
        labAliquotID: 'Aliquot',
        bio_informatic_analysis: "Type d'analyse",
        workflow_name: 'Pipeline',
        workflow_version: 'Version',
        genome_build: 'Génome build',
        analysis_id: `ID d'analyse`,
        identifiant: 'Identifiant',
      },
      analysisFiles: "Fichiers générés par l'analyse",
      numberByExperimentalStrategy: 'Nombre par stratégie',
      numberByDataTypes: 'Nombre par type de données',
      'n=2': '(n={count})',
      nTooltipParticipant: 'Nombre total de fichiers associés au participant',
      nTooltipFile: "Nombre total de fichiers dans l'étude",
    },
    variant: {
      variant: 'Variant',
      variants: 'Variants',
      variantAuto: '{count, plural, =0 {Variant} =1 {Variant} other {Variants}}',
      type: 'Type',
      variant_class: 'Type de variant',
      variant_external_reference: 'Référence externe',
      variant_id: 'ID de variant',
      variant_external_references: 'Références externes',
      zygosity: 'Zygosité',
      transmission: 'Transmission',
      panels: 'Panels de gènes',
      gnomAD: 'gnomAD',
      gnomADTooltip: 'gnomAD Genome 3.1.2 (fréquence allélique)',
      gnomADAlt: 'gnomAD ALT',
      gnomADAltTooltip: "gnomAD Genome 3.1.2 (# d'allèles alternatifs)",
      gnomadGenome311: 'gnomAD Genome (v3.1.1)',
      gnomadGenome3: 'gnomAD Genome (v3.1.2)',
      genome_build: 'Genome build',
      dbsnp: 'dbSNP',
      chromosome: 'Chromosome',
      position: 'Position',
      cytoband: 'Cytobande',
      alternativeAllele: 'Allèle alternatif',
      referenceAllele: 'Allèle de référence',
      referenceGenome: 'Génome de référence',
      sources: 'Sources',
      genes: 'Gènes',
      gene: 'Gène',
      geneType: 'Type de gène',
      genePhenotype: 'Gène - Phénotype',
      mane: 'MANE',
      manePlus: 'MANE Plus',
      maneSelect: 'MANE Select',
      canonical: 'Ensembl Canonical',
      ensembl: 'Ensembl',
      noGene: 'Aucun gène',
      typeAbrv: {
        insertion: 'Ins',
        deletion: 'Del',
        snv: 'SNV',
        null: 'ND',
        indel: 'Ind',
        substitution: 'Sub',
        sequence_alteration: 'SeqAlt',
      },
      typeAbrvTooltip: {
        insertion: 'Insertion',
        deletion: 'Délétion',
        snv: 'SNV',
        null: 'No Data',
        indel: 'Indel',
        substitution: 'Substitution',
        sequence_alteration: 'Séquence Altération',
      },
      studiesTooltip: "# d'études avec des participants affectés",
      homozygotes: {
        title: 'Homo.',
        tooltip: "# d'homozygotes pour les allèles alternatifs",
      },
      alt: {
        title: 'ALT',
        tooltip: '# d’allèles alternatifs',
      },
      consequences: {
        consequence: 'Conséquence',
        consequences: 'Conséquences',
        geneConsequences: 'Conséquences géniques',
        impactTag: {
          modifier: 'MODIFIER',
          low: 'BAS',
          moderate: 'MODÉRÉ',
          high: 'HAUT',
        },
        impactTooltip: {
          HIGH: 'Haut',
          LOW: 'Bas',
          MODERATE: 'Modéré',
          MODIFIER: 'Modifier',
        },
        predictions: {
          predictions: 'Prédictions',
          prediction: 'Prédiction',
          sift: 'Sift',
          polyphen2: 'Polyphen2',
          fathmm: 'Fathmm',
          cadd: 'CADD',
          caddPhred: 'CaddPhred',
          caddRaw: 'CaddRaw',
          caddTooltip: 'CADD (score Phred)',
          dann: 'Dann',
          lrt: 'Lrt',
          revel: 'REVEL',
        },
        aaColumn: 'AA',
        aaColumnTooltip: 'Substitution acide aminé',
        cdnaChangeColumn: 'ADN codant',
        conservation: 'Conservation',
        strand: 'Brin',
        vep: 'VEP',
        transcript: 'Transcrit',
        transcripts: 'Transcrits',
        refSeq: 'RefSeq',
        seeMoreRefSeq: 'RefSeq - {ensemblTranscriptId}',
        omim: 'OMIM',
        omimTooltip: "Modes d'héritage MIM",
        hideTranscript: 'Afficher moins',
        showTranscript: '{count} autres transcrits',
        canonical: 'Transcrit canonique',
      },
      details: {
        functionalScores: 'Scores de prédictions',
        geneConstraints: 'Contraintes géniques',
        spliceAltering: "Altération de l'épissage",
        associatedConditions: 'Conditions associées (OMIM)',
        sift: 'SIFT',
        fathmm: 'FATHMM',
        caddPhred: 'CADD (Phred)',
        caddRaw: 'CADD (Raw)',
        dann: 'DANN',
        lrt: 'LRT',
        revel: 'REVEL',
        rEVEL: 'REVEL',
        polyphen2: 'PolyPhen-2 HVAR',
        polyphen2hvar: 'PolyPhen-2 HVAR',
        phyloP17Way: 'PhyloP17Way',
        pickedTooltip: 'Gène avec la conséquence la plus délétère',
        spliceAi: 'SpliceAI',
        pli: 'pLI',
        loeuf: 'LOEUF',
        spliceAiType: {
          AG: 'Acceptor gain',
          AL: 'Acceptor loss',
          DG: 'Donor gain',
          DL: 'Donor loss',
        },
      },
      frequencies: {
        frequency: 'Fréquence',
        frequencyOnlyWGS: 'Fréquence (WGS seulement)',
        frequencies: 'Fréquences',
        frequencyTooltip: 'Fréquence du variant dans les études du CQDG',
        publicCohorts: 'Cohortes publiques',
        studies: 'Études',
        domain: 'Domaine',
        part: 'Part.',
        freq: 'Fréq.',
        freqTooltip:
          'Fréquence allélique du variant dans les cohortes CQDG (génomes entiers seulement)',
        participants: 'Participants',
        participantsTooltip:
          'Nombre et fréquence de participants porteurs dans les cohortes du CQDG (génomes entiers seulement)',
        participantsInfoIconTooltip:
          'En raison de la confidentialité des participants, les liens peuvent renvoyer un nombre inférieur à celui affiché',
        altAlleles: '# Allèles ALT',
        altAllelesTooltip: 'Nombre d’allèles alternatifs',
        altRef: '# Allèles (ALT + REF)',
        altRefTooltip: 'Allèles alternatifs + Allèles de référence',
        homozygotes: '# Homozygotes',
        homozygotesTooltip: 'Nombre de variants homozygotes',
        total: 'Total',
        cohort: 'Cohorte',
        internal_frequencies_wgs_af: 'Fréq. allélique CQDG (WGS)',
        internal_frequencies_wgs_pf: 'Fréq. participant CQDG (WGS)',
      },
      pathogenicity: {
        pathogenicity: 'Pathogénicité',
        pathogenicities: 'Pathogénicités',
        clinVar: 'ClinVar',
        clinvarAbrv: {
          conflicting_interpretations_of_pathogenicity: 'CI',
          benign: 'B',
          likely_benign: 'LB',
          uncertain_significance: 'VUS',
          pathogenic: 'P',
          not_provided: 'NP',
          drug_response: 'DR',
          risk_factor: 'RF',
          likely_pathogenic: 'LP',
          association: 'AS',
          other: 'O',
          affects: 'AF',
          protective: 'PV',
          confers_sensitivity: 'CS',
          uncertain_risk_allele: 'URA',
          association_not_found: 'ANF',
          likely_risk_allele: 'LRA',
          low_penetrance: 'LPN',
        },
        clinVarLabel: {
          affects: 'Affects',
          association: 'Association',
          association_not_found: 'Association Not Found',
          benign: 'Benign',
          confers_sensitivity: 'Confers Sensitivity',
          conflicting_interpretations_of_pathogenicity:
            'Conflicting Interpretations Of Pathogenicity',
          drug_response: 'Drug Response',
          likely_benign: 'Likely Benign',
          likely_pathogenic: 'Likely Pathogenic',
          likely_risk_allele: 'Likely Risk Allele',
          low_penetrance: 'Low Penetrance',
          not_provided: 'Not Provided',
          null: 'Aucune donnée',
          other: 'Autre',
          pathogenic: 'Pathogenic',
          protective: 'Protective',
          risk_factor: 'Risk Factor',
          uncertain_risk_allele: 'Uncertain Risk Allele',
          uncertain_significance: 'Uncertain Significance',
        },
        pathoClinvar: 'Pathogénicité (ClinVar)',
        condition: 'Condition',
        inheritance: 'Héritage',
        source: 'Source',
        interpretation: 'Interprétation',
        germline: 'Germline',
        germlineAbvr: 'GER',
        somaticAbvr: 'SOM',
        somatic: 'Somatic',
      },
      conditions: {
        title: 'Condition',
        tableTitle: 'Association gène - phénotype',
      },
      mostDeleteriousConsequence: {
        title: 'Conséquence la plus délétère',
        tooltip: "Conséquences fonctionnelles des variations génétiques annotées à l'aide de VEP",
      },
      inheritant: {
        code: {
          AD: 'Autosomal Dominant',
          AR: 'Autosomal Recessive',
          DD: 'Digenic Dominant',
          DR: 'Digenic Recessive',
          IC: 'Isolated Cases',
          Mi: 'Mitochondrial',
          MI: 'Mitochondrial Inheritance',
          Mu: 'Multifactorial',
          NRT: 'No Reported Transmission',
          SMo: 'Somatic Mosaicism',
          Smu: 'Somatic Mutation',
          XL: 'X-Linked',
          XLD: 'X-Linked Dominant',
          XLR: 'X-Linked Recessive',
          YL: 'Y-Linked',
        },
      },
    },
  },
  global: {
    yes: 'Oui',
    no: 'Non',
    other: 'Autre',
    delete: 'Supprimer',
    unknown: 'Inconnu',
    summary: 'Résumé',
    viewInDataExploration: 'Voir sur la page Explorateur',
    search: {
      genes: {
        emptyText: 'Aucun gène trouvé',
        placeholder: 'ex : BRAF, ENSG00000157764',
        title: 'Recherche par gène',
        tooltip: 'Entrer un Symbole de gène, Alias de gène ou Ensembl ID',
      },
      variants: {
        emptyText: 'Aucun variant trouvé',
        placeholder: 'ex : 10-100063679-T-C, rs341',
        title: 'Recherche par variant',
        tooltip:
          'Entrer un Locus de Variant, Symbole de gène, Alias de gène, Gène AA Change, dbSNP ID, ClinVar ID, Ensembl ID, refseq ID',
      },
      study: {
        emptyText: 'Aucune étude trouvée',
        placeholder: 'ex : DEE, Developmental and Epileptic Encephalopathies',
        title: 'Rechercher une étude',
        tooltip: 'Recherche par code, nom, domaine de l’étude ou mot-clé',
      },
    },
    filters: {
      actions: {
        all: 'Tout',
        none: 'Aucun',
        clear: 'Effacer',
        less: 'Moins',
        more: 'de plus',
        apply: 'Appliquer',
        dictionary: 'Dictionnaire',
        cancel: 'Annuler',
      },
      operators: {
        allOf: 'Tout',
        anyOf: 'N’importe quel',
        noneOf: 'Aucun',
        between: 'Entre',
        lessthan: 'Moins que',
        lessthanorequal: 'Moins que ou égale',
        greaterthan: 'Plus grand que',
        greaterthanorequal: 'Plus grand que ou égale',
      },
      range: {
        is: 'Est',
        from: 'De',
        to: 'A',
        actualInterval: 'Intervalle actuel',
      },
      messages: {
        empty: 'Aucune valeur trouvée',
      },
      checkbox: {
        placeholder: 'Rechercher...',
      },
    },
    forms: {
      errors: {
        minCharacters: 'caractères minimum',
        requiredField: 'Ce champ est requis',
        enterValidEmail: 'Entrer un email valide',
      },
    },
    errors: {
      403: "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
      404: "Désolé, cette page n'existe pas.",
      500: 'Désolé, quelque chose en va pas.',
      backHome: 'Retour accueil',
    },
    notification: {
      genericError: 'Une erreur est apparue',
    },
    proTable: {
      results: 'Résultats',
      result: 'Résultat',
      noResults: 'Aucun résultat',
      of: 'de',
      selected: 'élément sélectionné',
      selectedPlural: 'éléments sélectionnés',
      selectAllResults: 'Sélectionner tous les résultats',
      clear: 'Effacer',
      clearFilters: 'Effacer les filtres',
      tableExport: 'Exporter en TSV',
      reset: 'Réinitialiser',
      columns: 'Colonnes',
      first: 'Début',
      previous: 'Précédent',
      next: 'Suivant',
      view: '{value} / écran',
    },
    quickFilter: {
      emptyMessage: 'Min. 3 caractères',
      menuTitle: 'Rechercher',
      placeholder: 'Rechercher...',
      results: 'Résultats',
    },
    seeLess: 'Voir moins',
    seeMore: 'Voir plus',
    ferload: 'Ferload',
  },
  api: {
    savedFilter: {
      error: {
        title: 'Erreur',
        messageUpdate: 'Impossible de modifier le filtre',
        messageDelete: 'Impossible de supprimer le filtre',
      },
    },
    savedSet: {
      error: {
        title: 'Erreur',
        messageUpdate: "Impossible de modifier l'ensemble",
        messageDelete: "Impossible de supprimer l'ensemble",
        messageCreate: "Impossible de créer l'ensemble",
      },
      success: {
        titleCreate: 'Votre ensemble a été sauvegardé.',
        messageCreate: 'Vous pouvez ajouter vos ensembles a une requête ou au tableau de bord.',
        titleUpdate: 'Succès',
        messageUpdate: 'Votre ensemble a été modifié.',
        titleDelete: 'Succès',
        messageDelete: 'Votre ensemble a été supprimé.',
      },
    },
    report: {
      error: {
        title: 'Erreur',
        message:
          "Nous n'avons pas pu générer le rapport pour le moment. Veuillez réessayer plus tard ou ",
        support: 'contactez le support',
        tooMuchFilesTitle: 'Nombre maximal dépassé',
        tooMuchFiles:
          'Un maximum de {limit} fichiers peuvent être inclus à la fois. Veuillez restreindre votre sélection et réessayer.',
      },
      inProgress: {
        title: 'Traitement',
        fetchReport: 'Récupération du rapport, veuillez patienter',
      },
      onSuccess: {
        title: 'Succès',
        fetchReport: 'Rapport téléchargé avec succès',
      },
      clinicalData: {
        download: 'Données cliniques',
        family:
          '{count, plural, =0 {Participant séléctionné et famille} =1 {Participant séléctionné et famille} other {Participants séléctionnés et familles}}',
        participant:
          '{count, plural, =0 {Participant séléctionné} =1 {Participant séléctionné} other {Participants séléctionnés}}',
      },
      sampleData: {
        download: "Données d'échantillons",
      },
      fileManifest: {
        button: 'Manifeste',
        title: 'Fichier manifeste',
        okText: 'Télécharger',
        cancel: 'Annuler',
        text: `Télécharger un manifeste des fichiers sélectionnés. Celui-ci pourra être utilisé par l'outil de téléchargement rapide des données du CQDG. Ce manifeste comprend également des informations supplémentaires, notamment sur les participants et les échantillons associés à ces fichiers.`,
        textStudy: `Téléchargez un manifeste des fichiers de cette étude. Celui-ci pourra être utilisé par l'outil de téléchargement rapide des données du CQDG. Ce manifeste comprend également des informations supplémentaires, notamment sur les participants et les échantillons associés à ces fichiers.`,
        textDataset: `Téléchargez un manifeste des fichiers de ce jeu de données. Celui-ci pourra être utilisé par l'outil de téléchargement rapide des données du CQDG. Ce manifeste comprend également des informations supplémentaires, notamment sur les participants et les échantillons associés à ces fichiers.`,
        textCheckbox: `Inclure les fichiers de données de même type des membres de la famille des participants représentés dans les fichiers sélectionnés.`,
        textCheckboxSet:
          'Inclure tous les fichiers de même type sur les membres des familles représentées dans cet ensemble.',
        tooltip: 'Manifeste de fichiers pour l’outil ',
        manifestIdButton: 'Copier l’identifiant',
        manifestIdButtonTooltip: "Copiez l'identifiant du manifeste à utiliser avec ",
        manifestIdCopySuccess: 'Identifiant copié dans le presse-papiers',
        manifestIdCopyError: 'Erreur de copie de l’identifiant dans le presse-papiers',
      },
      requestAccess: {
        button: 'Demande d’accès',
        title: 'Demande d’accès',
        okText: 'Télécharger',
        cancel: 'Annuler',
        text: 'Pour obtenir l’accès aux données de cette étude, veuillez formuler votre demande auprès de l’autorité d’accès ci-dessous :',
        text2:
          'Avant de formuler votre demande, assurez-vous d’être en mesure de répondre aux exigences et aux restrictions d’accès décrites dans la section « Accès aux données ».',
        text3: 'Pour en savoir davantage, veuillez consulter la page ',
        content:
          'Téléchargez une archive contenant des documents pour vous guider dans vos demandes d’accès. Vous y trouverez les conditions d’utilisation et les coordonnées de l’autorité d’accès pour chaque étude, ainsi que la liste des fichiers par étude qui font l’objet de la demande d’accès.',
        content2: 'Pour plus de détails, consultez le fichier README_FR dans l’archive ou la page ',
        textLink: `Demande d'accès aux données`,
        textCheckbox: `Inclure tous les fichiers de même type sur les membres des familles représentées dans cette sélection.`,
      },
    },
    noData: 'Aucune donnée',
    noDataAvailable: 'Aucune donnée',
  },
  components: {
    search: {
      searchByIds: 'Recherche par identifiants',
      selectSavedSet: 'Sélectionner un ensemble',
      searchByParticipantIdTooltip: 'Recherche par ID de participant ou ID de participant externe',
      savedParticipantSets: 'Ensemble de participants',
      noParticipantFound: 'Aucun participant trouvé',
      noParticipantSetFound: 'Aucun ensemble de participants trouvé',
      participantPlaceholder: 'ex : PT0483333',
      searchBySampleIdTooltip: 'Recherche par ID d’échantillon ou ID d’échantillon externe',
      savedSampleSets: 'Ensemble d’échantillons',
      noSampleFound: 'Aucun sample trouvé',
      noSampleSetFound: 'Aucun ensemble d’échantillons trouvé',
      samplePlaceholder: 'ex : SR0230956',
      savedFileSets: 'Ensemble de fichiers',
      noFileFound: 'Aucun fichier trouvé',
      noFileSetFound: 'Aucun ensemble de fichier trouvé',
      filePlaceholder: 'ex : FI0080837',
      searchByFileIdTooltip: 'Recherche par ID de fichier',
    },
    uploadIds: {
      participantPlaceholder: 'ex : PT0483333',
      samplePlaceholder: 'ex : SR0230956',
      filePlaceholder: 'ex : FI0080837',
      sampleTitle: "Téléverser une liste d'échantillons",
      gene: 'gène',
      geneID: 'Gene Symbole, Gene Alias, Ensembl ID',
      genePlaceholder: 'ex. ENSG00000157764, TP53',
      title: 'Téléverser une liste de {entity}s',
      submittedColTitle: 'Identifiants soumis',
      uploadBtnText: 'Téléverser une liste de {entity}s',
      mappedTo: 'Correspond à',
      collapseTitle: 'Résumé ({matchCount} reconnus, {unMatchCount} inconnus)',
      inputLabel: 'Copier-coller une liste d’identifiants ou téléverser un fichier',
      match: '({count}) Reconnus',
      unmatch: '({count}) Inconnus',
      tableMessage:
        '{submittedCount} identifiants soumis correspondant à {mappedCount} identifiants système uniques',
      matchTable: {
        idcol: 'ID {entity}',
        participant: {
          matchcol: 'ID du participant',
          mappedcol: "Code de l'étude",
        },
        file: {
          matchcol: 'ID du fichier',
          mappedcol: "Code de l'étude",
        },
        biospecimen: {
          matchcol: "ID de l'échantillon",
          mappedcol: "Code de l'étude",
        },
        gene: {
          matchcol: 'Ensembl ID',
          mappedcol: 'Symbole',
        },
      },
      pillTitle: 'Liste téléchargée',
      upload: {
        fileBtn: 'Téléverser un fichier',
        btn: 'Téléverser',
      },
      clearBtn: 'Supprimer',
      cancelBtn: 'Annuler',
      emptyTable: 'Aucune donnée',
      popover: {
        title: 'Identifiants et formats de fichiers',
        identifiers: 'Identifiants',
        separatedBy: {
          title: 'Séparé par',
          values: 'virgule, espace, nouvelle ligne',
        },
        uploadFileFormats: 'Télécharger des formats de fichiers',
      },
    },
    filterList: {
      collapseAll: 'Tout fermer',
      expandAll: 'Tout ouvrir',
    },
    querybuilder: {
      defaultTitle: 'Filtre sans titre',
      header: {
        modal: {
          edit: {
            title: 'Sauvegarder ce filtre',
            okText: 'Sauvegarder',
            cancelText: 'Annuler',
            input: {
              label: 'Nom du filtre',
              placeholder: 'Filtre sans titre',
              maximumLength: 'caractères maximum',
            },
          },
          saveThisFilter: 'Sauvegarder ce filtre',
          confirmUnsaved: {
            title: 'Modifications non sauvegardées',
            openSavedFilter: {
              okText: 'Continuer',
              cancelText: 'Annuler',
              content:
                "Vous êtes sur le point d'ouvrir un filtre sauvegardé; toutes les modifications seront perdues.",
            },
            createNewFilter: {
              okText: 'Créer',
              cancelText: 'Annuler',
              content:
                'Vous êtes sur le point de créer un nouveau filtre; toutes les modifications seront perdues.',
            },
          },
        },
        popupConfirm: {
          delete: {
            title: 'Supprimer définitivement ce filtre ?',
            okText: 'Supprimer le filtre',
            cancelText: 'Annuler',
            content:
              'Vous êtes sur le point de supprimer définitivement ce filtre et toutes ses requêtes.',
          },
        },
        tooltips: {
          newQueryBuilder: 'Nouveau filtre',
          save: 'Sauvegarder le filtre',
          saveChanges: 'Sauvegarder les changements',
          delete: 'Supprimer',
          duplicateQueryBuilder: 'Dupliquer le filtre',
          saveDisabled: 'Ajouter une requête à enregistrer',
          share: "Partager (Copier l'URL)",
          shareDisabled: 'Enregistrer le filtre à partager',
          setAsDefaultFilter: 'Définir comme filtre par défaut',
          unsetDefaultFilter: 'Filtre par défaut non défini',
          undoChanges: 'Filtre par défaut non défini',
          noSavedFilters: "Vous n'avez aucun filtre sauvegardé",
        },
        myFiltersDropdown: {
          title: 'Mes filtres',
          manageMyFilter: 'Gérer les filtres',
          okText: 'Fermer',
        },
        duplicateFilterTitleSuffix: 'COPIER',
      },
      query: {
        combine: {
          and: 'et',
          or: 'ou',
        },
        noQuery: 'Utilisez les outils de recherche et les facettes à gauche pour créer une requête',
      },
      actions: {
        new: 'Nouveau',
        changeOperatorTo: "Changer d'opérateur pour",
        addQuery: 'Nouvelle requête',
        combine: 'Combiner',
        labels: 'Étiquettes',
        delete: {
          title: 'Supprimer cette requête?',
          titleSelected: 'Supprimer cette requête?',
          cancel: 'Annuler',
          confirm: 'Supprimer',
        },
        clear: {
          title: 'Supprimer toutes les requêtes?',
          cancel: 'Annuler',
          confirm: 'Supprimer',
          buttonTitle: 'Tout effacer',
          description:
            'Vous êtes sur le point de supprimer toutes vos requêtes. Ils seront perdus à jamais.',
        },
      },
      pattern: 'Caractères permis : A-Z a-z 0-9 ()[]-_:|,.',
    },
    savedSets: {
      modal: {
        edit: {
          title: "Modifier l'ensemble",
          okText: 'Sauvegarder',
          cancelText: 'Annuler',
          input: {
            label: "Nom de l'ensemble",
            placeholder: 'Entrer le nom de votre ensemble',
            maximumLength: 'Caractères maximum',
          },
        },
        add: {
          title: 'Ajouter a un ensemble {type}',
          okText: 'Ajouter a un ensemble',
          cancelText: 'Annuler',
          enterName: 'Entrer le nom de votre nouvel ensemble',
          name: "Nom de l'ensemble",
        },
        remove: {
          title: "Retirer d'un ensemble {type}",
          okText: "Retirer d'un ensemble",
          cancelText: 'Annuler',
        },
      },
      popupConfirm: {
        delete: {
          title: 'Supprimer définitivement cet ensemble ?',
          okText: 'Supprimer cet ensemble',
          cancelText: 'Annuler',
          content: 'Vous êtes sur le point de supprimer définitivement cet ensemble.',
        },
      },
    },
    dataRelease: {
      title: 'Données disponibles',
      dataReleaseLink: 'Version 1.0',
      dataExploration: 'Exploration des données',
    },
  },
  layout: {
    main: {
      menu: {
        dashboard: 'Tableau de bord',
        studies: 'Études',
        explore: 'Explorateur',
        variants: 'Variants',
        website: 'Site web',
        documentation: 'Documentation',
        dictionary: 'Dictionnaire',
        community: 'Communauté',
        resources: 'Ressources',
        contact: 'Contact',
        downloadTool: 'Outil de téléchargement',
      },
    },
    user: {
      menu: {
        settings: 'Paramètres de profil',
        logout: 'Se déconnecter',
        signedWith: 'Connecté en tant que',
      },
    },
  },
  screen: {
    loginPage: {
      title: 'Portail de données',
      resume:
        "Le Centre Québécois de Données Génomiques est une plateforme d'harmonisation et de diffusion des données génomiques générées par les études cliniques et de recherche du Québec.",
      login: 'Connexion',
      signup: 'Créer compte',
      studies: {
        title: 'Études',
        summary:
          'Explorez les données harmonisées d’études dans les populations pédiatriques et adultes.',
        cartagene: {
          subtitle: 'CARTaGENE',
          description:
            "<p>CARTaGENE est une plateforme publique de recherche du CHU Sainte-Justine créée pour accélérer la recherche en santé. CARTaGENE est constitué à la fois d'échantillons biologiques et de données de santé provenant de 43 000 résidents du Québec âgés de 40 à 69 ans.</p><p>Le jeu de données présentement hébergé au CQDG comprend les données de séquençage du génome entier (WGS) de 2 179 individus.</p>",
        },
        dee: {
          title: 'DEE',
          subtitle: 'Investigation des causes génétiques des encéphalopathies épileptogènes',
          description:
            "<p>Étude de séquençage du génome entier (WGS) chez des enfants atteints d'encéphalopathies épileptogènes.</p><p>Le jeu de données présentement hébergé au CQDG comprend les données de séquençage du génome entier de 588 individus appartenant à 196 familles.</p>",
        },
        bacq: {
          title: 'BACQ',
          subtitle: 'Biobanque des anomalies congénitales au Québec',
          description:
            '<p>Biobanque de cas affectés bien caractérisés ainsi que de leurs parents permettant de mener des études de génétique moléculaire pour identifier les gènes associés à ces malformations.</p><p>Le jeu de données présentement hébergé au CQDG comprend les données de séquençage de l’exome entier (WXS) de 386 individus appartenant à 124 familles.</p>',
        },
        pragmatiq: {
          title: 'PRAGMatIQ',
          subtitle: 'Pratique rapide de la génomique pédiatrique au Québec',
          description:
            "<p>L'objectif du projet est d'offrir un séquençage clinique rapide du génome entier (WGS) à tous les nouveau-nés et nourrissons gravement malades qui pourraient bénéficier de ce test au Québec.</p><p>Les données de 257 individus ont été soumises au CQDG. Le jeu de données présentement hébergé au CQDG comprend les données de séquençage du génome entier de 110 individus appartenant à 38 familles.</p>",
        },
        neurodev: {
          title: 'NeuroDev',
          subtitle: 'Troubles du neuro-développement',
          description:
            "<p>L'objectif principal de ce projet est de constituer une banque d'échantillons et de données provenant d'enfants et d'adultes atteints d'un retard global de développement, d'épilepsie et/ou de malformations et de membres de leur famille pour alimenter divers projets de recherche qui visent à identifier les gènes impliqués dans ces pathologies.</p><p>Les données de 382 individus ont été soumises au CQDG. Le jeu de données présentement hébergé au CQDG comprend les données de séquençage du génome entier de 67 individus appartenant à 22 familles.</p>",
        },
      },
      cards: {
        stats: {
          release: 'Données CQDG v1.0',
          genomes: 'Génomes',
          exomes: 'Exomes',
        },
        rare: {
          title: 'Réseau pour Avancer la REcherche sur les maladies rares au Québec',
          description:
            'Le CQDG est un fier partenaire du nouveau Réseau pour Avancer la REcherche sur les maladies rares au Québec (RARE.Qc) financé par le FRQS. RARE.Qc est un regroupement de plus 100 chercheurs et patients partenaires à travers le Québec, qui vise à transformer la recherche sur les maladies rares.',
        },
        secureData: {
          title: 'CQDG dans l’infonuage',
          description:
            'Analysez les données du CQDG avec vos propres logiciels sur l’infrastructure infonuagique Secure Data for Health (SD4H). D’autres infonuages seront également intégrés au CQDG dans un avenir rapproché. Contactez-nous pour en savoir plus.',
          contact: 'Contactez-nous',
        },
        variants: {
          title: 'Variants germinaux',
          description:
            'Notre explorateur de variants offre des fonctions de recherche avancées. En quelques clics, vous pouvez explorer des millions de variants germinaux annotés issus des génomes et exomes des participants du CQDG.',
          explore: 'Explorer les données de variants',
        },
      },
      documentation: {
        title: 'Centre de documentation',
        description:
          'Pour savoir comment soumettre vos données, faire une demande d’accès ou pour consulter des guides d’utilisation du portail, visitez le centre de documentation.',
        button: 'Centre de documentation',
      },
      hosting: {
        title: 'Données à héberger ?',
        description:
          'Le CQDG offre aux chercheurs une plateforme idéale pour entreposer et diffuser les données génomiques générées par les études cliniques et de recherche.',
        button: 'Soumettre vos données',
      },
    },
    memberProfile: {
      notFound: 'Membre non trouvé',
      rolesTitle: 'Rôle(s)',
      noRoles: 'Aucun rôle',
      usageTitle: 'Utilisation prévue des données du portail CQDG',
      researchDomainsTitle: 'Domaine(s) de recherche ou domaine(s) d’intérêt',
      noUsage: 'Aucune utilisation',
      noResearchDomain: 'Aucun domaine de recherche',
      editProfileBtn: 'Modifier le profil',
      communityBtn: 'Communauté',
    },
    community: {
      title: 'Communauté CQDG',
      resultsMember: 'Membres',
      noMember: 'Aucun membre',
      search: {
        filters: 'Filtres',
        inputPlaceholder: 'ex : Bergeron, Sainte-Justine',
        selectPlaceholder: 'Sélectionner',
        role: 'Rôle',
        dataUse: 'Utilisation des données',
        researchDomain: 'Domaine de recherche',
        clearFilters: 'Effacer les filtres',
        barPlaceholder: 'Recherche par nom ou affiliation',
        noResult: 'Aucun résultat',
        sorter: {
          newest: 'Plus récent',
          oldest: 'Plus ancien',
          lastnameAlpha: 'Alphabétique (nom de famille)',
        },
      },
    },
    profileSettings: {
      title: 'Paramètres de profil',
      viewProfile: 'Voir le profil',
      cards: {
        deleteAccount: {
          title: 'Supprimer le compte',
          button: 'Supprimer mon compte',
          notice:
            'Vous ne pourrez plus vous connecter au portail de données CQDG. Tous vos ensembles et requêtes sauvegardés seront perdus. Vous pouvez créer un nouveau compte à tout moment.',
          confirm: {
            content: 'Êtes-vous sûr(e) de vouloir supprimer définitivement ce compte ?',
          },
        },
        identification: {
          title: 'Identification',
          alert:
            "Vous êtes authentifié(e) avec <strong>{provider}</strong> utilisant <strong>{email}</strong>. Cet e-mail n'est jamais affiché au public et ne peut pas être modifié.",
          firstName: 'Prénom',
          yourFirstName: 'Votre prénom',
          lastName: 'Nom de famille',
          yourLastName: 'Votre nom de famille',
          publicEmail: 'E-mail public',
          publicEmailNotice:
            'Cet e-mail sera affiché sur votre page de profil et accessible à tous les utilisateurs connectés du portail.',
          editPhotoModalTitle: 'Modifier photo',
          uploadImageError: 'Impossible de télécharger votre image pour le moment',
          removePhotoModalTitle: 'Supprimer la photo de profil ?',
          removePhotoModalButton: 'Oui supprimer la photo',
          removePhotoModalMessage:
            'Voulez-vous vraiment supprimer votre photo ? Nous le remplacerons par un avatar par défaut.',
          uploadPhotoButton: 'Envoyer la photo',
          removePhotoButton: 'Supprimer la photo',
          linkedinUrl: 'Veuillez entrer une URL LinkedIn valide',
        },
        roleAffiliation: {
          title: 'Rôle et affiliation',
          iama: 'Je suis un(e)',
          checkAllThatApply: 'Cochez tout ce qui s’applique',
          provideAffiliation: 'Indiquez votre affiliation institutionnelle ou organisationnelle',
          affiliatedWith: 'Je suis affilié(e) à',
          dontHaveAffiliation: "Je n'ai pas d'affiliation institutionnelle",
          describeResearchArea:
            "Mon domaine de recherche ou domaine d'intérêt peut être décrit comme",
          provideABriefLink:
            'Fournissez une brève description et un lien vers votre biographie professionnelle ou le site Web de votre organisation, si disponible',
        },
        researchDomain: {
          title: 'Domaine de recherche',
          label: 'Domaine(s) de recherche ou domaine(s) d’intérêt',
          checkAll: 'Cochez tout ce qui s’applique',
        },
        saveChanges: 'Sauvegarder les modifications',
        discardChanges: 'Annuler les modifications',
      },
      roleOptions: {
        researcher_in_academic_or_non_profit_institution:
          'Chercheur(e) dans une institution académique ou sans but lucratif',
        representative_of_commercial_or_for_profit_company:
          "Représentant(e) d'une entreprise commerciale ou à but lucratif",
        bioinformatician_software_developer: 'Bioinformaticien(ne), développeur(e) logiciel',
        clinician: 'Clinicien(ne)',
        employee_in_governmental_agency: 'Employé(e) d’un organisme gouvernemental',
        other: 'Autre',
      },
      researchDomainOptions: {
        aging: 'Vieillissement',
        bioinformatics: 'Bioinformatique',
        birth_defects: 'Malformations congénitales',
        cancer: 'Cancer',
        circulatory_respiratory_health: 'Santé circulatoire et respiratoire',
        general_health: 'Santé générale',
        infection_immunity: 'Infection et immunité',
        musculoskeletal_health_arthritis: 'Santé musculo-squelettique et arthrite',
        neurodevelopmental_conditions: 'Conditions neuro-développementales',
        neurosciences_mental_health_addiction: 'Neurosciences, santé mentale et toxicomanie',
        nutrition_metabolism_diabetes: 'Nutrition, métabolisme et diabète',
        population_genomics: 'Génomique des populations',
        rare_diseases: 'Maladies rares',
        not_applicable: "Ne s'applique pas",
        other: 'Autre',
      },
    },
    dashboard: {
      hello: 'Bonjour',
      cards: {
        error: {
          title: 'Erreur de connexion',
          subtitle:
            'Nous ne sommes actuellement pas en mesure de nous connecter à ce service. Veuillez actualiser la page et réessayer. Si le problème persiste, veuillez',
          contactSupport: 'contactez le support',
          pleaseRefresh: 'Veuillez actualiser et réessayer ou ',
          failedFetch: 'Échec de la récupération des filtres sauvegardés',
        },
        savedFilters: {
          title: 'Mes filtres',
          popoverTitle: 'Gestions des filtres sauvegardés',
          noSaved:
            "Un filtre est un ensemble de requêtes appliquées sur les données harmonisées du CQDG. Les résultats d'un filtre peuvent changer lors d'une mise à jour des données.",
          howToCreate: 'Comment créer un filtre',
        },
        savedSets: {
          title: 'Mes ensembles',
          popoverTitle: 'Gestion des ensembles sauvegardés',
          noSaved:
            "Un ensemble est une collection fixe d'entités (participants, biospécimens, fichiers ou variants) qui demeure inchangé malgré la mise à jour des données.",
          howToCreate: 'Comment créer un ensemble',
        },
        lastSaved: 'Dernier enregistrement : il y a {date}',
        learnMore: 'En savoir plus',
      },
    },
    variants: {
      title: 'Banque des variants germinaux',
      subTitle: 'Explorez l’ensemble des variants germinaux soumis par les études du CQDG.',
      variantsExploration: 'Exploration des variants',
      sidemenu: {
        participant: 'Participant',
        variant: 'Variant',
        gene: 'Gène',
        frequency: 'Fréquence',
        pathogenicity: 'Pathogénicité',
      },
    },
    studies: {
      title: 'Répertoire des études',
      code: 'Code',
      name: 'Nom',
      domain: 'Domaine',
      population: 'Population',
      participants: 'Participants',
      families: 'Familles',
      genomics: 'Génomique',
      transcriptomics: 'Transcriptomique',
      imaging: 'Imagerie',
      files: 'Fichiers',
      accessLimitation: 'Restriction d’accès',
      accessRequirement: 'Exigence d’accès',
      sampleAvailability: 'Disponibilité des échantillons',
      description: 'Description',
      searchLabel: {
        title: 'Recherche par code, nom, domaine de l’étude ou mot-clé',
        placeholder: 'ex. CAG, CARTaGENE, Rare Diseases, Epilepsy',
      },
    },
    dataExploration: {
      title: 'Explorateur de données',
      subTitle:
        'Explorez et constituez des cohortes virtuelles de participants provenant de plusieurs études du CQDG.',
      sidemenu: {
        participant: 'Participant',
        biospecimen: 'Biospécimen',
        datafiles: 'Fichier de données',
      },
      hpoTree: {
        modal: {
          title: 'Navigateur de phénotype observé (HPO)',
          okText: 'Appliquer',
          cancelText: 'Annuler',
        },
        searchPlaceholder: "Recherche par terme d'ontologie - min 3 caractères",
        emptySelection:
          'Sélectionnez des éléments dans le volet de gauche afin de les ajouter à votre requête.',
        tags: {
          exact: 'Participants avec ce terme exact',
          all: 'Participants y compris les termes descendants',
        },
      },
      mondoTree: {
        modal: {
          title: 'Navigateur de diagnostic (MONDO)',
          okText: 'Appliquer',
          cancelText: 'Annuler',
        },
        searchPlaceholder: "Recherche par terme d'ontologie - min 3 caractères",
        emptySelection:
          'Sélectionnez des éléments dans le volet de gauche afin de les ajouter à votre requête.',
        tags: {
          exact: 'Participants avec ce terme exact',
          all: 'Participants y compris les termes descendants',
        },
      },
      icdTree: {
        modal: {
          title: 'Navigateur de diagnostic (ICD-10)',
          okText: 'Appliquer',
          cancelText: 'Annuler',
        },
        searchPlaceholder: "Recherche par terme d'ontologie - min 3 caractères",
        emptySelection:
          'Sélectionnez des éléments dans le volet de gauche afin de les ajouter à votre requête.',
        tags: {
          exact: 'Participants avec ce terme exact',
          all: 'Participants y compris les termes descendants',
        },
      },
      tabs: {
        summary: {
          title: 'Résumé',
          global: {
            nbParticipant:
              '{count} participants (y compris les termes descendants sur cette arborescence)',
            addTermToQuery: 'Ajouter un terme à la requête active',
            currentPath: 'Arborescence actuelle',
            centerSubtitleFormatter: 'Participants avec',
          },
          download: {
            fileNameTemplate: 'cqdg-%name-%type-%date%extension',
            fileNameDateFormat: 'yyyy-MM-dd',
            download: 'Télécharger',
            preview: 'Aperçu du téléchargement - ',
            data: 'Télécharger les données',
            svg: 'Télécharger SVG',
            png: 'Télécharger PNG',
            removeChart: 'Supprimer le graphique',
          },
          columnSelector: {
            reset: 'Réinitialiser',
            tooltip: 'Graphiques',
          },
          observed_phenotypes: {
            cardTitle: 'Phénotypes observés (HPO)',
            empty: 'Aucun phénotype observé signalé pour ces participants',
            phenotypeTree: {
              nbParticipant:
                '{count} participants (y compris les termes descendants sur cette arborescence)',
              addTermToQuery: 'Ajouter un terme à la requête active',
              currentPath: 'Arborescence actuelle',
            },
          },
          mondo: {
            cardTitle: 'Diagnostic (MONDO)',
            empty: 'Aucun diagnostic signalé pour ces participants',
            phenotypeTree: {
              nbParticipant:
                '{count} participants (y compris les termes descendants sur cette arborescence)',
              addTermToQuery: 'Ajouter un terme à la requête active',
              currentPath: 'Arborescence actuelle',
            },
          },
          demographic: {
            cardTitle: 'Profils sociodémographiques',
            ethnicityTitle: 'Ethnicité',
            compositionFamilyTitle: 'Composition familiale',
          },
          ageAtDiagnosis: {
            cardTitle: 'Age at Diagnosis',
            _0to1: 'Newborn',
            _1to5: '[1, 5]',
            _5to10: '[5, 10]',
            _10to15: '[10, 15]',
            _15to18: '[15, 18]',
            _18plus: 'Adult',
          },
          studies: {
            cardTitle: 'Studies',
          },
          availableData: {
            dataCategoryTitle: 'Participants par catégorie de données',
            dataTypeTitle: 'Participants par type de données',
            studiesTitle: 'Participants par étude',
            axis: '# de participants',
            dataCategory: 'Catégorie de données',
            dataType: 'Type de données',
          },
          studiespie: {
            cardTitle: 'Études',
            domainTitle: 'Domaine',
            popTitle: 'Population',
            partTitle: 'Participants',
          },
        },
        participants: {
          title: 'Participants ({count})',
        },
        biospecimens: {
          title: 'Biospécimens ({count})',
        },
        datafiles: {
          title: 'Fichiers ({count})',
          facet: 'Fichier',
        },
      },
      saveSelection: 'Sauvegarder la sélection',
      participantsSelected: 'participants séléctionnés',
      participantSelected: 'participant séléctionné',
      saveParticipantsSet: "Sauvegarder l'ensemble de participants",
      maxLimit: 'Max. {limit} éléments à la fois. Les {limit} premiers seront traités.',
      filesSelected: 'fichiers séléctionnés',
      fileSelected: 'fichier séléctionné',
      saveFilesSet: "Sauvegarder l'ensemble de fichiers",
      biospecimensSelected: 'biospécimens séléctionnés',
      biospecimenSelected: 'biospécimen séléctionné',
      saveBiospecimensSet: "Sauvegarder l'ensemble de biospécimens",
      variantsSelected: 'variants séléctionnés',
      variantSelected: 'variant séléctionné',
      saveVariantsSet: "Sauvegarder l'ensemble de variants",
      searchByBiospecimenId: 'Recherche par ID biospécimen',
      searchByBiospecimenSampleId: 'Recherche par ID d’échantillon',
      noBiospecimenFound: 'Aucun biospécimen trouvé',
      noBiospecimenSetsFound: 'Aucun ensemble de biospécimen trouvé',
      noBiospecimenCollectionFound: 'Aucune ID collection trouvé',
      savedBiospecimenSets: 'Ensemble d’échantillons',
      saveAsNewSet: 'Créer un nouvel ensemble',
      addToExistingSet: 'Ajouter à un ensemble existant',
      removeFromExistingSet: "Supprimer d'un ensemble existant",
      addToSet: "Ajouter à l'ensemble",
      addTypeSet: "Ajouter à l'ensemble de {type}",
      removeFromSet: "Retirer de l'ensemble",
      removeTypeSet: "Retirer de l'ensemble de {type}",
      set: 'Ensemble',
      chooseSet: 'Choisissez un ensemble',
      youMustSelect: 'Sélectionnez au moins un élément',
      comingSoon: 'Bientôt disponible !',
    },
    cavatica: {
      title: 'Connecter à Cavatica',
      analyseModal: {
        title: 'Analyse dans Cavatica',
        copyFiles: 'Copier fichiers',
        youAreAuthorizedToCopy: 'Vous êtes autorisé à copier',
        countFiles: '{filesAuthorizedCount} fichiers',
        ofFiles: ' (sur {filesTotalCount} sélectionnés) vers votre espace de travail Cavatica.',
        unauthorizedFiles: 'Fichiers non autorisés',
        unauthorizedFilesDescription:
          "Vous n'êtes pas autorisé à analyser les fichiers sélectionnés. Pour plus de détails, consultez la page ",
        dataAccess: "Demande d'accès aux données",
      },
    },
  },
  // this facets object is also used by Ferlab-ui to override the values in the facets
  // ex: variant genes sift_pred: T -> Tolerated
  facets: {
    options: {
      genes__consequences__predictions__sift_pred: {
        T: 'Tolerated',
        D: 'Damaging',
      },
      genes__consequences__predictions__polyphen2_hvar_pred: {
        B: 'Benign',
        D: 'Damaging',
        P: 'Possibly Damaging',
      },
      genes__consequences__predictions__fathmm_pred: {
        T: 'Tolerated',
        D: 'Damaging',
      },
      genes__consequences__predictions__lrt_pred: {
        N: 'Neutral',
        D: 'Deleterious',
        U: 'Unknown',
      },
      studies__zygosity: {
        HET: 'Heterozygote',
        WT: 'Wild Type',
        HOM: 'Homozygote',
        UNK: 'Unknown',
      },
      variant_external_reference: {
        DBSNP: 'dbSNP',
        Clinvar: 'ClinVar',
        Cosmic: 'COSMIC',
      },
      age_at_recruitment: ageCategoriesKeyLabelTooltip,
      mondo_tagged__age_at_event: ageCategoriesKeyLabelTooltip,
      age_biospecimen_collection: ageCategoriesKeyLabelTooltip,
      biospecimens__age_biospecimen_collection: ageCategoriesKeyLabelTooltip,
    },
  },
};

export default fr;
