/* eslint-disable max-len */

import { IconProps } from 'components/Icons';

const CQDGLogoFull = ({ width = 152, height = 52, className = '' }: IconProps) => (
  <svg
    width={width}
    height={height}
    className={className}
    viewBox="0 0 152 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M43.1057 23.9524C42.7427 24.495 42.2504 24.9375 41.6737 25.2395C41.097 25.5414 40.4543 25.6932 39.8043 25.6809C37.3283 25.6809 34.9702 23.7321 34.9702 20.1801V20.1395C34.9702 16.5808 37.2845 14.6319 39.7942 14.6319C40.43 14.619 41.06 14.7564 41.6334 15.033C42.2068 15.3097 42.7076 15.7179 43.0956 16.2249V14.8251H46.1005V28.6532H43.0956L43.1057 23.9524ZM43.1461 20.1734V20.1327C43.1461 18.3635 41.9772 17.1908 40.5758 17.1908C39.1744 17.1908 37.9852 18.3432 37.9852 20.1327V20.1801C37.9852 21.9493 39.171 23.122 40.5758 23.122C41.9805 23.122 43.1461 21.9425 43.1461 20.1801V20.1734Z"
      fill="#FFFFFF"
    />
    <path
      d="M47.1382 20.1802V20.1395C47.1382 16.5808 49.4491 14.632 51.9588 14.632C52.5947 14.6188 53.2247 14.7561 53.7981 15.0328C54.3716 15.3094 54.8724 15.7178 55.2602 16.225V10.9683H58.2651V25.4844H55.2602V23.9524C54.8972 24.4951 54.4049 24.9376 53.8282 25.2396C53.2515 25.5415 52.6088 25.6933 51.9588 25.6809C49.4896 25.6809 47.1382 23.7321 47.1382 20.1802ZM55.3006 20.1802V20.1395C55.3006 18.3704 54.135 17.1977 52.7303 17.1977C51.3255 17.1977 50.1397 18.35 50.1397 20.1395V20.1802C50.1397 21.9494 51.3289 23.1221 52.7303 23.1221C54.1317 23.1221 55.3006 21.9426 55.3006 20.1802Z"
      fill="#FFFFFF"
    />
    <path
      d="M59.8246 27.5892L60.8555 25.3252C61.9365 25.9712 63.1724 26.3076 64.4297 26.2979C66.488 26.2979 67.4615 25.3049 67.4615 23.3967V22.8917C66.5722 23.9661 65.6054 24.6202 64.0019 24.6202C61.5326 24.6202 59.2856 22.8104 59.2856 19.6516V19.6109C59.2856 16.4284 61.5595 14.6389 64.0019 14.6389C64.6547 14.625 65.3021 14.7612 65.8947 15.0373C66.4873 15.3134 67.0093 15.7219 67.4211 16.2319V14.8253H70.426V23.0781C70.426 24.9863 69.9712 26.3792 69.0785 27.274C68.0915 28.267 66.5688 28.7043 64.5139 28.7043C62.8839 28.7218 61.2743 28.339 59.8246 27.5892ZM67.4548 19.638V19.5974C67.4548 18.1468 66.3296 17.13 64.8643 17.13C63.3989 17.13 62.2973 18.1468 62.2973 19.5974V19.638C62.2973 21.109 63.4022 22.102 64.8643 22.102C66.3263 22.102 67.4548 21.0886 67.4548 19.638Z"
      fill="#FFFFFF"
    />
    <path
      d="M36.8839 9.27932C34.5624 5.6231 31.1167 2.81929 27.0649 1.28938C23.0131 -0.240521 18.5742 -0.413834 14.4155 0.795498C10.2567 2.00483 6.60293 4.53145 4.00336 7.99553C1.40379 11.4596 -0.00106362 15.6739 6.03794e-07 20.0049C0.00106483 24.3359 1.40799 28.5495 4.00926 32.0123C6.61053 35.4751 10.2656 38 14.4249 39.2072C18.5842 40.4145 23.0231 40.239 27.0741 38.7071C31.1251 37.1752 34.5694 34.3697 36.8892 30.7124L33.1736 28.3557C31.3642 31.2084 28.6776 33.3967 25.5178 34.5916C22.358 35.7864 18.8957 35.9233 15.6514 34.9817C12.4071 34.04 9.55621 32.0706 7.52722 29.3696C5.49823 26.6686 4.40083 23.382 4.4 20.0038C4.39917 16.6257 5.49496 13.3385 7.52262 10.6365C9.55028 7.93453 12.4002 5.96377 15.6441 5.02049C18.8879 4.07721 22.3502 4.21239 25.5106 5.40572C28.671 6.59904 31.3586 8.78602 33.1695 11.6379L36.8839 9.27932Z"
      fill="#FFFFFF"
    />
    <g clipPath="url(#clip0_2392_37284)">
      <mask id="mask0_2392_37284" maskUnits="userSpaceOnUse" x="8" y="8" width="24" height="24">
        <circle cx="20.0103" cy="20.0101" r="11.9456" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_2392_37284)">
        <path
          d="M13.8464 12.0792C14.5575 12.0792 15.1339 11.4993 15.1339 10.7839C15.1339 10.0685 14.5575 9.48853 13.8464 9.48853C13.1354 9.48853 12.5589 10.0685 12.5589 10.7839C12.5589 11.4993 13.1354 12.0792 13.8464 12.0792Z"
          fill="#C54B38"
        />
        <rect
          x="11.7917"
          y="11.0121"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 11.7917 11.0121)"
          fill="#00AEEF"
        />
        <rect
          x="6.9978"
          y="15.8062"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 6.9978 15.8062)"
          fill="#FFB600"
        />
        <rect
          x="20.9236"
          y="1.88037"
          width="2.58284"
          height="9.68565"
          rx="1.29142"
          transform="rotate(45 20.9236 1.88037)"
          fill="#C54B38"
        />
        <path
          d="M8.82397 22.124C9.53504 22.124 10.1115 21.5441 10.1115 20.8287C10.1115 20.1133 9.53504 19.5333 8.82397 19.5333C8.11291 19.5333 7.53648 20.1133 7.53648 20.8287C7.53648 21.5441 8.11291 22.124 8.82397 22.124Z"
          fill="#C54B38"
        />
        <rect
          x="13.6182"
          y="14.2081"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 13.6182 14.2081)"
          fill="#FFB600"
        />
        <rect
          x="16.5859"
          y="11.2404"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 16.5859 11.2404)"
          fill="#A8AD00"
        />
        <rect
          x="21.3801"
          y="6.44629"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 21.3801 6.44629)"
          fill="#00AEEF"
        />
        <rect
          x="6.76929"
          y="21.057"
          width="2.58284"
          height="7.74852"
          rx="1.29142"
          transform="rotate(45 6.76929 21.057)"
          fill="#00AEEF"
        />
        <path
          d="M17.0425 18.9282C17.7535 18.9282 18.33 18.3483 18.33 17.6329C18.33 16.9175 17.7535 16.3375 17.0425 16.3375C16.3314 16.3375 15.755 16.9175 15.755 17.6329C15.755 18.3483 16.3314 18.9282 17.0425 18.9282Z"
          fill="#A8AD00"
        />
        <rect
          x="30.5117"
          y="2.33716"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 30.5117 2.33716)"
          fill="#C54B38"
        />
        <rect
          x="14.9878"
          y="17.8612"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 14.9878 17.8612)"
          fill="#00AEEF"
        />
        <rect
          x="20.0103"
          y="12.8386"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 20.0103 12.8386)"
          fill="#FFB600"
        />
        <rect
          x="10.1936"
          y="22.6553"
          width="2.58284"
          height="9.68565"
          rx="1.29142"
          transform="rotate(45 10.1936 22.6553)"
          fill="#C54B38"
        />
        <rect
          x="25.7175"
          y="7.13135"
          width="2.58284"
          height="7.74852"
          rx="1.29142"
          transform="rotate(45 25.7175 7.13135)"
          fill="#A8AD00"
        />
        <path
          d="M15.2161 25.7768C15.9271 25.7768 16.5036 25.1969 16.5036 24.4815C16.5036 23.7661 15.9271 23.1862 15.2161 23.1862C14.505 23.1862 13.9286 23.7661 13.9286 24.4815C13.9286 25.1969 14.505 25.7768 15.2161 25.7768Z"
          fill="#00AEEF"
        />
        <rect
          x="27.0874"
          y="10.7838"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 27.0874 10.7838)"
          fill="#FFB600"
        />
        <rect
          x="13.1616"
          y="24.7097"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 13.1616 24.7097)"
          fill="#FFB600"
        />
        <rect
          x="22.2932"
          y="15.578"
          width="2.58284"
          height="9.68565"
          rx="1.29142"
          transform="rotate(45 22.2932 15.578)"
          fill="#C54B38"
        />
        <rect
          x="7.22583"
          y="30.6454"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 7.22583 30.6454)"
          fill="#00AEEF"
        />
        <rect
          x="10.1936"
          y="27.6775"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 10.1936 27.6775)"
          fill="#A8AD00"
        />
        <path
          d="M12.02 33.9956C12.7311 33.9956 13.3075 33.4156 13.3075 32.7002C13.3075 31.9849 12.7311 31.4049 12.02 31.4049C11.309 31.4049 10.7325 31.9849 10.7325 32.7002C10.7325 33.4156 11.309 33.9956 12.02 33.9956Z"
          fill="#C54B38"
        />
        <rect
          x="35.5342"
          y="7.35974"
          width="2.58284"
          height="7.74852"
          rx="1.29142"
          transform="rotate(45 35.5342 7.35974)"
          fill="#A8AD00"
        />
        <rect
          x="14.9878"
          y="27.9061"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 14.9878 27.9061)"
          fill="#A8AD00"
        />
        <rect
          x="29.8269"
          y="13.067"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 29.8269 13.067)"
          fill="#C54B38"
        />
        <rect
          x="26.8591"
          y="16.0349"
          width="2.58284"
          height="9.68565"
          rx="1.29142"
          transform="rotate(45 26.8591 16.0349)"
          fill="#00AEEF"
        />
        <rect
          x="19.782"
          y="23.1119"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 19.782 23.1119)"
          fill="#FFB600"
        />
        <path
          d="M25.9458 25.0919C26.6569 25.0919 27.2333 24.512 27.2333 23.7966C27.2333 23.0812 26.6569 22.5012 25.9458 22.5012C25.2347 22.5012 24.6583 23.0812 24.6583 23.7966C24.6583 24.512 25.2347 25.0919 25.9458 25.0919Z"
          fill="#C54B38"
        />
        <rect
          x="16.8142"
          y="31.1019"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 16.8142 31.1019)"
          fill="#00AEEF"
        />
        <rect
          x="28.9136"
          y="19.0023"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 28.9136 19.0023)"
          fill="#A8AD00"
        />
        <rect
          x="23.8911"
          y="24.0248"
          width="2.58284"
          height="9.68565"
          rx="1.29142"
          transform="rotate(45 23.8911 24.0248)"
          fill="#A8AD00"
        />
        <rect
          x="33.7078"
          y="14.2081"
          width="2.58284"
          height="6.4571"
          rx="1.29142"
          transform="rotate(45 33.7078 14.2081)"
          fill="#FFB600"
        />
        <rect
          x="31.6533"
          y="21.2852"
          width="2.58284"
          height="7.74852"
          rx="1.29142"
          transform="rotate(45 31.6533 21.2852)"
          fill="#00AEEF"
        />
        <rect
          x="25.946"
          y="26.9926"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 25.946 26.9926)"
          fill="#FFB600"
        />
        <rect
          x="34.6211"
          y="18.3174"
          width="2.58284"
          height="3.87426"
          rx="1.29142"
          transform="rotate(45 34.6211 18.3174)"
          fill="#C54B38"
        />
        <rect
          x="22.978"
          y="29.9603"
          width="2.58284"
          height="9.68565"
          rx="1.29142"
          transform="rotate(45 22.978 29.9603)"
          fill="#00AEEF"
        />
      </g>
    </g>
    <path
      d="M43.0866 36.9983C43.0784 36.544 43.1618 36.0928 43.3318 35.6719C43.5018 35.251 43.7549 34.8691 44.0757 34.5494C44.3965 34.2296 44.7785 33.9787 45.1984 33.8116C45.6184 33.6446 46.0677 33.565 46.5191 33.5776C46.9984 33.5533 47.4773 33.6315 47.9243 33.8071C48.3714 33.9827 48.7763 34.2516 49.1124 34.5962L48.1921 35.6856C47.749 35.2255 47.1448 34.9577 46.5083 34.9393C46.2469 34.9448 45.9892 35.003 45.7506 35.1105C45.5119 35.218 45.2971 35.3726 45.1189 35.5651C44.9407 35.7576 44.8027 35.9841 44.7131 36.2312C44.6234 36.4783 44.584 36.741 44.5971 37.0038C44.5825 37.2676 44.6208 37.5318 44.7097 37.7805C44.7986 38.0292 44.9363 38.2574 45.1146 38.4515C45.2929 38.6456 45.5081 38.8017 45.7475 38.9104C45.987 39.0192 46.2457 39.0784 46.5083 39.0845C46.8336 39.0823 47.1549 39.0125 47.4521 38.8793C47.7494 38.7462 48.016 38.5528 48.2354 38.3111L49.1612 39.248C48.8282 39.6382 48.4116 39.9473 47.9427 40.1521C47.4738 40.3568 46.9648 40.4518 46.4541 40.43C46.0066 40.436 45.5624 40.351 45.1483 40.1801C44.7342 40.0092 44.3586 39.756 44.0441 39.4355C43.7296 39.1151 43.4827 38.734 43.3182 38.3152C43.1537 37.8964 43.0749 37.4485 43.0866 36.9983Z"
      fill="#FFFFFF"
    />
    <path
      d="M49.4374 37.7829C49.4221 37.4435 49.4751 37.1046 49.5931 36.7864C49.7112 36.4682 49.892 36.1772 50.1245 35.931C50.3571 35.6848 50.6367 35.4884 50.9466 35.3536C51.2565 35.2188 51.5903 35.1483 51.9279 35.1465C53.6116 35.1465 54.3804 36.4592 54.3804 37.8972C54.3804 38.0116 54.3804 38.1424 54.3804 38.2785H50.8775C50.9228 38.5681 51.0739 38.8303 51.3012 39.0136C51.5286 39.197 51.8157 39.2884 52.1065 39.2699C52.3372 39.2717 52.5657 39.2241 52.7767 39.1302C52.9876 39.0363 53.1763 38.8982 53.3301 38.7252L54.1476 39.4551C53.799 39.8703 53.3329 40.1688 52.8114 40.3109C52.2899 40.4529 51.7378 40.4317 51.2286 40.2501C50.7194 40.0685 50.2773 39.7351 49.9611 39.2943C49.645 38.8535 49.4699 38.3263 49.459 37.7829H49.4374ZM52.989 37.358C52.989 37.0691 52.8749 36.792 52.6719 36.5877C52.4688 36.3833 52.1934 36.2686 51.9062 36.2686C51.6224 36.2794 51.3532 36.3976 51.1524 36.5996C50.9516 36.8016 50.8341 37.0725 50.8234 37.358H52.989Z"
      fill="#FFFFFF"
    />
    <path
      d="M55.0845 35.217H56.5138V35.936C56.6737 35.689 56.8918 35.4856 57.1487 35.344C57.4057 35.2024 57.6935 35.127 57.9864 35.1244C59.0692 35.1244 59.6973 35.8434 59.6973 37.0036V40.2719H58.2679V37.4666C58.2679 36.7857 57.9485 36.4317 57.4071 36.4317C56.8657 36.4317 56.5138 36.7857 56.5138 37.4666V40.2936H55.0845V35.217Z"
      fill="#FFFFFF"
    />
    <path
      d="M60.807 38.8503V36.4482H60.2061V35.2172H60.807V33.9208H62.2363V35.2172H63.4166V36.4482H62.2363V38.627C62.2363 38.9538 62.3771 39.1173 62.6965 39.1173C62.9425 39.1186 63.1847 39.0567 63.4003 38.9375V40.0923C63.0592 40.2926 62.669 40.3926 62.2742 40.381C61.3917 40.3701 60.807 40.0215 60.807 38.8503Z"
      fill="#FFFFFF"
    />
    <path
      d="M64.0933 35.2172H65.5172V36.2412C65.6183 35.8951 65.8352 35.5949 66.1313 35.3913C66.4273 35.1878 66.7842 35.0936 67.1414 35.1246V36.6279H67.0656C66.1181 36.6279 65.5334 37.2053 65.5334 38.4146V40.2883H64.1095L64.0933 35.2172Z"
      fill="#FFFFFF"
    />
    <path
      d="M67.2604 37.7829C67.2451 37.4435 67.298 37.1046 67.4161 36.7864C67.5342 36.4682 67.715 36.1772 67.9475 35.931C68.1801 35.6848 68.4597 35.4884 68.7696 35.3536C69.0795 35.2188 69.4133 35.1483 69.7509 35.1465C71.4346 35.1465 72.2034 36.4592 72.2034 37.8972C72.2034 38.0116 72.2034 38.1424 72.2034 38.2785H68.6951C68.7404 38.5681 68.8915 38.8303 69.1188 39.0136C69.3461 39.197 69.6333 39.2884 69.9241 39.2699C70.1548 39.2717 70.3833 39.2241 70.5942 39.1302C70.8052 39.0363 70.9939 38.8982 71.1477 38.7252L71.9652 39.4551C71.6166 39.8703 71.1505 40.1688 70.629 40.3109C70.1075 40.4529 69.5554 40.4317 69.0462 40.2501C68.537 40.0685 68.0949 39.7351 67.7787 39.2943C67.4626 38.8535 67.2875 38.3263 67.2766 37.7829H67.2604ZM70.812 37.358C70.812 37.0691 70.6979 36.792 70.4949 36.5877C70.2918 36.3833 70.0164 36.2686 69.7292 36.2686C69.4454 36.2794 69.1762 36.3976 68.9754 36.5996C68.7746 36.8016 68.6571 37.0725 68.6464 37.358H70.812Z"
      fill="#FFFFFF"
    />
    <path
      d="M78.9765 39.5583C78.8035 39.816 78.5693 40.0262 78.2951 40.1699C78.021 40.3135 77.7155 40.386 77.4064 40.3808C76.2316 40.3808 75.1108 39.4548 75.1108 37.7608C75.1108 36.0667 76.1937 35.1407 77.4064 35.1407C77.7087 35.135 78.0081 35.2005 78.2807 35.3319C78.5533 35.4634 78.7916 35.6572 78.9765 35.8979V35.2333H80.4004V41.8243H78.9765V39.5583ZM78.9765 37.7608C78.9964 37.5868 78.9796 37.4106 78.927 37.2437C78.8745 37.0767 78.7875 36.9229 78.6717 36.7922C78.5559 36.6615 78.4139 36.5569 78.2551 36.4853C78.0963 36.4136 77.9242 36.3766 77.7502 36.3766C77.5762 36.3766 77.4041 36.4136 77.2453 36.4853C77.0865 36.5569 76.9445 36.6615 76.8287 36.7922C76.7129 36.9229 76.6259 37.0767 76.5734 37.2437C76.5208 37.4106 76.504 37.5868 76.5239 37.7608C76.504 37.9347 76.5208 38.1109 76.5734 38.2779C76.6259 38.4448 76.7129 38.5986 76.8287 38.7294C76.9445 38.8601 77.0865 38.9647 77.2453 39.0363C77.4041 39.1079 77.5762 39.1449 77.7502 39.1449C77.9242 39.1449 78.0963 39.1079 78.2551 39.0363C78.4139 38.9647 78.5559 38.8601 78.6717 38.7294C78.7875 38.5986 78.8745 38.4448 78.927 38.2779C78.9796 38.1109 78.9964 37.9347 78.9765 37.7608Z"
      fill="#FFFFFF"
    />
    <path
      d="M81.2666 38.5014V35.2332H82.6959V38.0602C82.6959 38.741 83.0153 39.0951 83.5622 39.0951C84.109 39.0951 84.4555 38.741 84.4555 38.0602V35.2332H85.8848V40.3044H84.4555V39.5853C84.2944 39.8324 84.0753 40.0357 83.8175 40.1773C83.5597 40.3189 83.2711 40.3943 82.9774 40.397C81.9217 40.3806 81.2666 39.6453 81.2666 38.5014Z"
      fill="#FFFFFF"
    />
    <path
      d="M86.5453 37.7825C86.53 37.4432 86.583 37.1043 86.701 36.786C86.8191 36.4678 86.9999 36.1769 87.2324 35.9307C87.465 35.6845 87.7447 35.4881 88.0545 35.3532C88.3644 35.2184 88.6982 35.148 89.0358 35.1461C90.7195 35.1461 91.4883 36.4589 91.4883 37.8969C91.4883 38.0113 91.4883 38.142 91.4883 38.2782H87.98C88.0253 38.5678 88.1764 38.8299 88.4037 39.0133C88.6311 39.1967 88.9182 39.288 89.209 39.2696C89.4397 39.2714 89.6682 39.2238 89.8791 39.1299C90.0901 39.0359 90.2788 38.8979 90.4326 38.7249L91.2501 39.4548C90.9015 39.87 90.4354 40.1685 89.9139 40.3105C89.3924 40.4526 88.8403 40.4314 88.3311 40.2498C87.8219 40.0681 87.3798 39.7347 87.0636 39.294C86.7475 38.8532 86.5724 38.326 86.5616 37.7825H86.5453ZM90.0969 37.3576C90.0969 37.0687 89.9828 36.7916 89.7798 36.5873C89.5767 36.383 89.3013 36.2682 89.0141 36.2682C88.7304 36.279 88.4611 36.3973 88.2603 36.5993C88.0595 36.8013 87.942 37.0722 87.9313 37.3576H90.0969ZM89.496 33L90.7249 33.5447L89.5014 34.6614H88.4456L89.496 33Z"
      fill="#FFFFFF"
    />
    <path
      d="M93.6053 39.6456V40.3101H92.176V33.3815H93.6053V35.947C93.7776 35.6886 94.0117 35.4778 94.286 35.3341C94.5603 35.1904 94.8662 35.1183 95.1754 35.1245C96.3503 35.1245 97.471 36.0505 97.471 37.7446C97.471 39.4386 96.3882 40.3646 95.1754 40.3646C94.8761 40.3761 94.5783 40.3171 94.3057 40.1923C94.0331 40.0674 93.7933 39.8802 93.6053 39.6456ZM96.0417 37.7827C96.065 37.6065 96.0506 37.4274 95.9995 37.2573C95.9484 37.0872 95.8618 36.9301 95.7454 36.7964C95.629 36.6627 95.4856 36.5556 95.3247 36.4822C95.1639 36.4088 94.9893 36.3708 94.8127 36.3708C94.6361 36.3708 94.4615 36.4088 94.3006 36.4822C94.1398 36.5556 93.9963 36.6627 93.88 36.7964C93.7636 36.9301 93.677 37.0872 93.6259 37.2573C93.5748 37.4274 93.5604 37.6065 93.5837 37.7827C93.5604 37.9588 93.5748 38.138 93.6259 38.3081C93.677 38.4782 93.7636 38.6353 93.88 38.769C93.9963 38.9027 94.1398 39.0098 94.3006 39.0832C94.4615 39.1566 94.6361 39.1946 94.8127 39.1946C94.9893 39.1946 95.1639 39.1566 95.3247 39.0832C95.4856 39.0098 95.629 38.9027 95.7454 38.769C95.8618 38.6353 95.9484 38.4782 95.9995 38.3081C96.0506 38.138 96.065 37.9588 96.0417 37.7827Z"
      fill="#FFFFFF"
    />
    <path
      d="M97.9044 37.7825C97.8891 37.4432 97.9421 37.1043 98.0602 36.786C98.1782 36.4678 98.359 36.1769 98.5916 35.9307C98.8242 35.6845 99.1038 35.4881 99.4137 35.3532C99.7236 35.2184 100.057 35.148 100.395 35.1461C102.079 35.1461 102.847 36.4589 102.847 37.8969C102.847 38.0113 102.847 38.142 102.847 38.2782H99.3446C99.3899 38.5678 99.541 38.8299 99.7683 39.0133C99.9956 39.1967 100.283 39.288 100.574 39.2696C100.804 39.2714 101.033 39.2238 101.244 39.1299C101.455 39.0359 101.643 38.8979 101.797 38.7249L102.615 39.4548C102.266 39.87 101.8 40.1685 101.278 40.3105C100.757 40.4526 100.205 40.4314 99.6957 40.2498C99.1864 40.0681 98.7443 39.7347 98.4282 39.294C98.112 38.8532 97.9369 38.326 97.9261 37.7825H97.9044ZM101.456 37.3576C101.456 37.0687 101.342 36.7916 101.139 36.5873C100.936 36.383 100.66 36.2682 100.373 36.2682C100.09 36.279 99.8203 36.3973 99.6195 36.5993C99.4187 36.8013 99.3012 37.0722 99.2904 37.3576H101.456ZM100.855 33L102.084 33.5447L100.871 34.6614H99.7885L100.855 33Z"
      fill="#FFFFFF"
    />
    <path
      d="M103.291 37.7825C103.287 37.4332 103.352 37.0866 103.484 36.7633C103.615 36.44 103.81 36.1466 104.057 35.9006C104.303 35.6546 104.596 35.461 104.919 35.3314C105.241 35.2018 105.586 35.1388 105.933 35.1461C106.31 35.1245 106.687 35.1899 107.035 35.3371C107.383 35.4844 107.694 35.7097 107.942 35.9959L107.07 36.9219C106.932 36.7504 106.757 36.6125 106.558 36.5182C106.359 36.4239 106.142 36.3757 105.922 36.3772C105.751 36.3862 105.584 36.4292 105.43 36.5038C105.275 36.5785 105.137 36.6831 105.023 36.8118C104.91 36.9405 104.822 37.0907 104.767 37.2535C104.711 37.4164 104.688 37.5888 104.699 37.7607C104.685 37.9385 104.707 38.1174 104.765 38.2861C104.823 38.4548 104.914 38.6097 105.034 38.7411C105.154 38.8725 105.3 38.9776 105.462 39.0497C105.624 39.1219 105.799 39.1597 105.977 39.1606C106.197 39.1553 106.413 39.1042 106.613 39.0105C106.812 38.9167 106.99 38.7824 107.135 38.6159L107.969 39.4711C107.72 39.774 107.405 40.0152 107.048 40.1757C106.691 40.3363 106.302 40.4121 105.912 40.3971C105.568 40.4029 105.226 40.3394 104.907 40.2104C104.588 40.0813 104.298 39.8893 104.054 39.6456C103.809 39.402 103.616 39.1116 103.485 38.7916C103.354 38.4716 103.288 38.1285 103.291 37.7825Z"
      fill="#FFFFFF"
    />
    <path
      d="M108.142 37.7825C108.142 37.0551 108.429 36.3575 108.941 35.8432C109.452 35.3289 110.145 35.0399 110.868 35.0399C111.591 35.0399 112.284 35.3289 112.796 35.8432C113.307 36.3575 113.594 37.0551 113.594 37.7825C113.594 38.5099 113.307 39.2075 112.796 39.7218C112.284 40.2362 111.591 40.5251 110.868 40.5251C110.145 40.5251 109.452 40.2362 108.941 39.7218C108.429 39.2075 108.142 38.5099 108.142 37.7825ZM112.181 37.7825C112.188 37.6027 112.16 37.4232 112.097 37.2547C112.034 37.0862 111.938 36.9321 111.815 36.8016C111.691 36.6711 111.543 36.5667 111.379 36.4948C111.216 36.4228 111.039 36.3847 110.86 36.3826C110.682 36.38 110.505 36.4145 110.34 36.484C110.176 36.5535 110.027 36.6564 109.904 36.7863C109.781 36.9162 109.686 37.0703 109.625 37.239C109.564 37.4076 109.539 37.5871 109.55 37.7662C109.542 37.9465 109.571 38.1264 109.635 38.2952C109.698 38.4641 109.794 38.6184 109.918 38.749C110.042 38.8796 110.19 38.9839 110.355 39.0555C110.52 39.1271 110.697 39.1647 110.876 39.1661C111.054 39.1687 111.231 39.1342 111.395 39.0647C111.559 38.9952 111.708 38.8921 111.83 38.7621C111.953 38.6321 112.047 38.4779 112.108 38.3093C112.168 38.1407 112.193 37.9613 112.181 37.7825Z"
      fill="#FFFFFF"
    />
    <path
      d="M114.281 33.3815H115.781V34.6615H114.281V33.3815ZM114.319 35.2171H115.749V40.2883H114.319V35.2171Z"
      fill="#FFFFFF"
    />
    <path
      d="M116.317 39.645L116.929 38.6972C117.386 39.0569 117.941 39.2678 118.521 39.3018C118.932 39.3018 119.122 39.1493 119.122 38.926C119.122 38.6155 118.634 38.512 118.077 38.3377C117.373 38.1307 116.572 37.793 116.572 36.818C116.572 35.7885 117.4 35.1838 118.418 35.1838C119.087 35.1938 119.74 35.3981 120.296 35.7721L119.755 36.7635C119.347 36.4962 118.881 36.3335 118.396 36.2896C118.044 36.2896 117.855 36.4421 117.855 36.6437C117.855 36.9269 118.337 37.0631 118.883 37.2483C119.587 37.4879 120.405 37.8257 120.405 38.7517C120.405 39.8792 119.566 40.3858 118.477 40.3858C117.696 40.3847 116.937 40.1243 116.317 39.645Z"
      fill="#FFFFFF"
    />
    <path
      d="M43 47.9195C43 46.2255 44.0828 45.2995 45.2956 45.2995C45.5979 45.2933 45.8975 45.3586 46.1701 45.4901C46.4428 45.6216 46.6811 45.8157 46.8657 46.0566V43.5619H48.295V50.4633H46.8657V49.7389C46.6923 49.9962 46.458 50.2062 46.1839 50.3498C45.9099 50.4934 45.6046 50.5661 45.2956 50.5614C44.1207 50.5396 43 49.6081 43 47.9195ZM46.8819 47.9195C46.9018 47.7456 46.885 47.5694 46.8324 47.4024C46.7799 47.2355 46.6929 47.0817 46.5771 46.9509C46.4613 46.8202 46.3193 46.7156 46.1605 46.644C46.0017 46.5724 45.8296 46.5354 45.6556 46.5354C45.4816 46.5354 45.3095 46.5724 45.1507 46.644C44.9919 46.7156 44.8499 46.8202 44.7341 46.9509C44.6183 47.0817 44.5313 47.2355 44.4788 47.4024C44.4262 47.5694 44.4094 47.7456 44.4293 47.9195C44.4094 48.0935 44.4262 48.2697 44.4788 48.4366C44.5313 48.6035 44.6183 48.7574 44.7341 48.8881C44.8499 49.0188 44.9919 49.1234 45.1507 49.195C45.3095 49.2667 45.4816 49.3037 45.6556 49.3037C45.8296 49.3037 46.0017 49.2667 46.1605 49.195C46.3193 49.1234 46.4613 49.0188 46.5771 48.8881C46.6929 48.7574 46.7799 48.6035 46.8324 48.4366C46.885 48.2697 46.9018 48.0935 46.8819 47.9195Z"
      fill="#FFFFFF"
    />
    <path
      d="M48.9557 47.9359C48.9396 47.5961 48.9921 47.2566 49.1098 46.9377C49.2275 46.6189 49.4082 46.3273 49.6408 46.0805C49.8735 45.8337 50.1534 45.6368 50.4636 45.5016C50.7739 45.3665 51.1081 45.2959 51.4462 45.2941C53.13 45.2941 53.8988 46.6123 53.8988 48.0503C53.8988 48.1592 53.8988 48.2954 53.8988 48.4261H50.3959C50.44 48.7163 50.5908 48.9791 50.8184 49.1627C51.046 49.3463 51.3338 49.4373 51.6248 49.4175C51.8535 49.4228 52.0807 49.3794 52.2916 49.2902C52.5024 49.2011 52.6922 49.0681 52.8484 48.9L53.666 49.6245C53.3173 50.0397 52.8512 50.3382 52.3298 50.4803C51.8083 50.6223 51.2561 50.6011 50.7469 50.4195C50.2377 50.2379 49.7956 49.9045 49.4795 49.4637C49.1633 49.0229 48.9882 48.4957 48.9774 47.9522L48.9557 47.9359ZM52.5073 47.511C52.5073 47.2221 52.3933 46.945 52.1902 46.7407C51.9871 46.5364 51.7117 46.4216 51.4245 46.4216C51.1408 46.4324 50.8715 46.5506 50.6708 46.7526C50.47 46.9547 50.3524 47.2255 50.3417 47.511H52.5073Z"
      fill="#FFFFFF"
    />
    <path
      d="M56.8054 47.9195C56.8054 46.2255 57.8882 45.2995 59.0956 45.2995C59.398 45.2925 59.6979 45.3574 59.9707 45.4889C60.2435 45.6205 60.4816 45.815 60.6656 46.0566V43.5619H62.095V50.4633H60.6656V49.7389C60.493 49.9969 60.2589 50.2074 59.9846 50.3511C59.7104 50.4948 59.4047 50.5671 59.0956 50.5614C57.9207 50.5396 56.8054 49.6081 56.8054 47.9195ZM60.6873 47.9195C60.7072 47.7456 60.6904 47.5694 60.6378 47.4024C60.5853 47.2355 60.4983 47.0817 60.3825 46.9509C60.2667 46.8202 60.1247 46.7156 59.9659 46.644C59.8071 46.5724 59.635 46.5354 59.461 46.5354C59.287 46.5354 59.1149 46.5724 58.9561 46.644C58.7973 46.7156 58.6553 46.8202 58.5395 46.9509C58.4237 47.0817 58.3367 47.2355 58.2842 47.4024C58.2317 47.5694 58.2148 47.7456 58.2347 47.9195C58.2148 48.0935 58.2317 48.2697 58.2842 48.4366C58.3367 48.6035 58.4237 48.7574 58.5395 48.8881C58.6553 49.0188 58.7973 49.1234 58.9561 49.195C59.1149 49.2667 59.287 49.3037 59.461 49.3037C59.635 49.3037 59.8071 49.2667 59.9659 49.195C60.1247 49.1234 60.2667 49.0188 60.3825 48.8881C60.4983 48.7574 60.5853 48.6035 60.6378 48.4366C60.6904 48.2697 60.7072 48.0935 60.6873 47.9195Z"
      fill="#FFFFFF"
    />
    <path
      d="M62.7561 47.936C62.7561 47.2086 63.0433 46.511 63.5545 45.9966C64.0657 45.4823 64.7591 45.1934 65.4821 45.1934C66.2051 45.1934 66.8984 45.4823 67.4096 45.9966C67.9209 46.511 68.2081 47.2086 68.2081 47.936C68.2081 48.6633 67.9209 49.3609 67.4096 49.8753C66.8984 50.3896 66.2051 50.6785 65.4821 50.6785C64.7591 50.6785 64.0657 50.3896 63.5545 49.8753C63.0433 49.3609 62.7561 48.6633 62.7561 47.936ZM66.8004 47.936C66.8077 47.7557 66.7788 47.5757 66.7156 47.4069C66.6523 47.238 66.5559 47.0837 66.4322 46.9531C66.3084 46.8225 66.1598 46.7183 65.9952 46.6466C65.8306 46.575 65.6533 46.5374 65.474 46.5361C65.2961 46.5335 65.1196 46.5679 64.9556 46.637C64.7915 46.7061 64.6434 46.8086 64.5204 46.9379C64.3975 47.0672 64.3023 47.2207 64.241 47.3886C64.1796 47.5566 64.1533 47.7355 64.1638 47.9142C64.1565 48.0945 64.1854 48.2744 64.2486 48.4432C64.3119 48.6121 64.4083 48.7664 64.532 48.897C64.6558 49.0276 64.8044 49.1319 64.969 49.2035C65.1336 49.2751 65.3109 49.3127 65.4902 49.3141C65.6681 49.3166 65.8445 49.2822 66.0086 49.2131C66.1727 49.144 66.3208 49.0415 66.4438 48.9122C66.5667 48.7829 66.6618 48.6294 66.7232 48.4615C66.7846 48.2935 66.8109 48.1146 66.8004 47.936Z"
      fill="#FFFFFF"
    />
    <path
      d="M68.8682 45.3759H70.2975V46.0949C70.4564 45.8465 70.6741 45.6418 70.9312 45.4992C71.1883 45.3566 71.4766 45.2805 71.7701 45.2778C72.8529 45.2778 73.4809 45.9968 73.4809 47.1625V50.4308H72.0516V47.6037C72.0516 46.9228 71.7322 46.5742 71.1908 46.5742C70.6494 46.5742 70.2975 46.9228 70.2975 47.6037V50.4308H68.8682V45.3759Z"
      fill="#FFFFFF"
    />
    <path
      d="M74.3425 45.3759H75.7719V46.0949C75.9316 45.8461 76.1503 45.6412 76.4083 45.4986C76.6663 45.356 76.9555 45.2801 77.2499 45.2778C78.3327 45.2778 78.9608 45.9968 78.9608 47.1625V50.4308H77.5368V47.6037C77.5368 46.9228 77.2174 46.5742 76.6706 46.5742C76.1238 46.5742 75.7773 46.9228 75.7773 47.6037V50.4308H74.3425V45.3759Z"
      fill="#FFFFFF"
    />
    <path
      d="M79.5883 47.9357C79.5722 47.596 79.6246 47.2564 79.7424 46.9376C79.8601 46.6187 80.0407 46.3271 80.2734 46.0803C80.506 45.8335 80.7859 45.6366 81.0962 45.5015C81.4064 45.3663 81.7407 45.2957 82.0787 45.2939C83.7625 45.2939 84.5313 46.6121 84.5313 48.0501C84.5313 48.159 84.5313 48.2952 84.5313 48.4259H81.0284C81.0725 48.7161 81.2233 48.979 81.4509 49.1626C81.6785 49.3462 81.9663 49.4371 82.2574 49.4173C82.4861 49.4226 82.7133 49.3792 82.9241 49.2901C83.135 49.2009 83.3247 49.0679 83.481 48.8998L84.2985 49.6243C83.9499 50.0396 83.4838 50.3381 82.9623 50.4801C82.4408 50.6221 81.8887 50.6009 81.3795 50.4193C80.8703 50.2377 80.4282 49.9043 80.112 49.4635C79.7959 49.0227 79.6208 48.4955 79.6099 47.9521L79.5883 47.9357ZM83.1399 47.5108C83.1399 47.2219 83.0258 46.9448 82.8227 46.7405C82.6197 46.5362 82.3443 46.4214 82.0571 46.4214C81.7733 46.4322 81.5041 46.5505 81.3033 46.7525C81.1025 46.9545 80.985 47.2254 80.9743 47.5108H83.1399ZM82.5389 43.1532L83.7679 43.6979L82.5498 44.8309H81.494L82.5389 43.1532Z"
      fill="#FFFFFF"
    />
    <path
      d="M84.9752 47.9359C84.9592 47.5961 85.0116 47.2566 85.1293 46.9377C85.2471 46.6189 85.4277 46.3273 85.6604 46.0805C85.893 45.8337 86.1729 45.6368 86.4832 45.5016C86.7934 45.3665 87.1276 45.2959 87.4657 45.2941C89.1495 45.2941 89.9183 46.6123 89.9183 48.0503C89.9183 48.1592 89.9183 48.2954 89.9183 48.4261H86.41C86.4541 48.7163 86.6049 48.9791 86.8325 49.1627C87.0601 49.3463 87.3479 49.4373 87.639 49.4175C87.8676 49.4228 88.0948 49.3794 88.3057 49.2902C88.5166 49.2011 88.7063 49.0681 88.8626 48.9L89.6801 49.6245C89.3315 50.0397 88.8654 50.3382 88.3439 50.4803C87.8224 50.6223 87.2703 50.6011 86.7611 50.4195C86.2518 50.2379 85.8097 49.9045 85.4936 49.4637C85.1774 49.0229 85.0023 48.4957 84.9915 47.9522L84.9752 47.9359ZM88.5269 47.511C88.5269 47.2221 88.4128 46.945 88.2097 46.7407C88.0067 46.5364 87.7312 46.4216 87.4441 46.4216C87.1603 46.4324 86.8911 46.5506 86.6903 46.7526C86.4895 46.9547 86.372 47.2255 86.3612 47.511H88.5269Z"
      fill="#FFFFFF"
    />
    <path
      d="M90.2104 49.7718L90.8222 48.824C91.277 49.1842 91.8307 49.3952 92.4086 49.4286C92.8255 49.4286 93.0095 49.2815 93.0095 49.0528C93.0095 48.7368 92.5223 48.6333 91.97 48.4645C91.2608 48.2575 90.4649 47.9198 90.4649 46.9393C90.4649 45.9098 91.2933 45.3052 92.3057 45.3052C92.9744 45.315 93.6263 45.5173 94.1844 45.888L93.643 46.8848C93.2337 46.6167 92.7652 46.4539 92.2786 46.4109C91.9321 46.4109 91.7372 46.5634 91.7372 46.7595C91.7372 47.0428 92.2137 47.1735 92.7605 47.3642C93.4643 47.5984 94.2818 47.9415 94.2818 48.8675C94.2818 49.9951 93.4481 50.5017 92.3544 50.5017C91.5796 50.5013 90.8263 50.2449 90.2104 49.7718Z"
      fill="#FFFFFF"
    />
    <path
      d="M97.4225 51.4439L97.9098 50.3545C98.4252 50.6642 99.0149 50.8262 99.6152 50.8229C100.59 50.8229 101.05 50.349 101.05 49.4394V49.1943C100.865 49.4528 100.621 49.6627 100.338 49.8059C100.055 49.9491 99.7423 50.0214 99.4257 50.0168C99.1214 50.0203 98.8195 49.9607 98.5391 49.8417C98.2587 49.7226 98.0056 49.5467 97.7958 49.3249C97.586 49.103 97.4238 48.84 97.3196 48.5523C97.2153 48.2646 97.1711 47.9584 97.1897 47.6527C97.1719 47.3473 97.2166 47.0414 97.3212 46.754C97.4258 46.4667 97.588 46.204 97.7976 45.9824C98.0073 45.7607 98.26 45.5848 98.5401 45.4654C98.8201 45.3461 99.1216 45.286 99.4257 45.2887C99.7357 45.2837 100.043 45.3491 100.324 45.4802C100.605 45.6114 100.853 45.8047 101.05 46.0459V45.3868H102.479V49.3087C102.51 49.6678 102.469 50.0295 102.359 50.3726C102.249 50.7158 102.073 51.0336 101.84 51.3077C101.236 51.8128 100.458 52.0575 99.6748 51.9886C98.8908 51.9973 98.1169 51.8102 97.4225 51.4439ZM101.05 47.6636C101.029 47.3506 100.891 47.0572 100.663 46.8428C100.436 46.6284 100.135 46.5092 99.8237 46.5092C99.5118 46.5092 99.2116 46.6284 98.9839 46.8428C98.7562 47.0572 98.618 47.3506 98.5974 47.6636C98.618 47.9767 98.7562 48.2701 98.9839 48.4845C99.2116 48.6988 99.5118 48.8181 99.8237 48.8181C100.135 48.8181 100.436 48.6988 100.663 48.4845C100.891 48.2701 101.029 47.9767 101.05 47.6636Z"
      fill="#FFFFFF"
    />
    <path
      d="M103.118 47.9357C103.103 47.5957 103.156 47.256 103.274 46.9372C103.393 46.6183 103.574 46.3267 103.807 46.08C104.04 45.8333 104.32 45.6365 104.631 45.5014C104.941 45.3663 105.276 45.2957 105.614 45.2939C107.292 45.2939 108.066 46.6121 108.066 48.0501C108.066 48.159 108.066 48.2952 108.066 48.4259H104.564C104.608 48.7161 104.759 48.979 104.986 49.1626C105.214 49.3462 105.502 49.4371 105.793 49.4173C106.021 49.4226 106.248 49.3792 106.459 49.2901C106.67 49.2009 106.86 49.0679 107.016 48.8998L107.834 49.6243C107.484 50.0387 107.018 50.3363 106.496 50.4778C105.975 50.6193 105.423 50.5979 104.914 50.4165C104.404 50.2351 103.962 49.9023 103.645 49.4621C103.328 49.022 103.152 48.4953 103.14 47.9521L103.118 47.9357ZM106.675 47.5108C106.675 47.2219 106.561 46.9448 106.358 46.7405C106.155 46.5362 105.879 46.4214 105.592 46.4214C105.309 46.4322 105.039 46.5505 104.838 46.7525C104.638 46.9545 104.52 47.2254 104.509 47.5108H106.675ZM106.074 43.1532L107.303 43.6979L106.08 44.8309H105.029L106.074 43.1532Z"
      fill="#FFFFFF"
    />
    <path
      d="M108.754 45.3759H110.183V46.0949C110.341 45.8459 110.559 45.6406 110.816 45.4979C111.073 45.3552 111.362 45.2795 111.656 45.2778C112.739 45.2778 113.367 45.9968 113.367 47.1625V50.4308H111.937V47.6037C111.937 46.9228 111.618 46.5742 111.077 46.5742C110.535 46.5742 110.183 46.9228 110.183 47.6037V50.4308H108.754V45.3759Z"
      fill="#FFFFFF"
    />
    <path
      d="M113.989 47.936C113.989 47.2086 114.276 46.511 114.788 45.9966C115.299 45.4823 115.992 45.1934 116.715 45.1934C117.438 45.1934 118.132 45.4823 118.643 45.9966C119.154 46.511 119.441 47.2086 119.441 47.936C119.439 48.2911 119.365 48.6422 119.225 48.9683C119.086 49.2945 118.882 49.5891 118.627 49.8349C118.372 50.0806 118.071 50.2725 117.741 50.3992C117.412 50.5258 117.06 50.5847 116.707 50.5723C116.355 50.5863 116.003 50.5284 115.674 50.4021C115.344 50.2758 115.043 50.0837 114.789 49.8374C114.535 49.5911 114.334 49.2957 114.196 48.969C114.059 48.6422 113.988 48.2908 113.989 47.936ZM118.034 47.936C118.041 47.7557 118.012 47.5757 117.949 47.4069C117.885 47.238 117.789 47.0837 117.665 46.9531C117.542 46.8225 117.393 46.7183 117.228 46.6466C117.064 46.575 116.886 46.5374 116.707 46.5361C116.529 46.5335 116.353 46.5679 116.189 46.637C116.025 46.7062 115.877 46.8086 115.754 46.9379C115.631 47.0672 115.535 47.2207 115.474 47.3887C115.413 47.5566 115.386 47.7355 115.397 47.9142C115.39 48.0945 115.419 48.2744 115.482 48.4432C115.545 48.6121 115.641 48.7664 115.765 48.897C115.889 49.0276 116.038 49.1319 116.202 49.2035C116.367 49.2752 116.544 49.3128 116.723 49.3141C116.901 49.3166 117.078 49.2823 117.242 49.2131C117.406 49.144 117.554 49.0415 117.677 48.9122C117.8 48.7829 117.895 48.6295 117.956 48.4615C118.018 48.2935 118.044 48.1146 118.034 47.936Z"
      fill="#FFFFFF"
    />
    <path
      d="M120.102 45.3754H121.531V46.0944C121.692 45.8452 121.912 45.6401 122.171 45.4975C122.43 45.3549 122.72 45.2792 123.015 45.2774C123.303 45.2599 123.591 45.3267 123.842 45.4698C124.094 45.6128 124.299 45.8259 124.433 46.0835C124.629 45.8299 124.881 45.6253 125.168 45.4854C125.455 45.3456 125.771 45.2744 126.09 45.2774C127.173 45.2774 127.79 45.9201 127.79 47.1403V50.4412H126.361V47.6142C126.361 46.9333 126.063 46.5847 125.527 46.5847C124.991 46.5847 124.661 46.9333 124.661 47.6142V50.4412H123.231V47.6142C123.231 46.9333 122.928 46.5847 122.392 46.5847C121.856 46.5847 121.531 46.9333 121.531 47.6142V50.4412H120.102V45.3754Z"
      fill="#FFFFFF"
    />
    <path
      d="M128.683 43.54H130.188V44.8147H128.683V43.54ZM128.721 45.3757H130.151V50.4415H128.721V45.3757Z"
      fill="#FFFFFF"
    />
    <path
      d="M134.758 49.7173C134.584 49.9747 134.35 50.1846 134.076 50.3282C133.802 50.4718 133.497 50.5445 133.188 50.5398C132.013 50.5398 130.892 49.6083 130.892 47.9197C130.892 46.2257 131.975 45.2997 133.188 45.2997C133.49 45.2936 133.79 45.3588 134.062 45.4903C134.335 45.6218 134.573 45.8159 134.758 46.0569V45.3978H136.187V51.956H134.758V49.7173ZM134.758 47.9197C134.778 47.7458 134.761 47.5696 134.708 47.4027C134.656 47.2357 134.569 47.0819 134.453 46.9512C134.337 46.8205 134.195 46.7159 134.036 46.6442C133.878 46.5726 133.705 46.5356 133.531 46.5356C133.357 46.5356 133.185 46.5726 133.027 46.6442C132.868 46.7159 132.726 46.8205 132.61 46.9512C132.494 47.0819 132.407 47.2357 132.355 47.4027C132.302 47.5696 132.285 47.7458 132.305 47.9197C132.285 48.0937 132.302 48.2699 132.355 48.4368C132.407 48.6038 132.494 48.7576 132.61 48.8883C132.726 49.019 132.868 49.1236 133.027 49.1953C133.185 49.2669 133.357 49.3039 133.531 49.3039C133.705 49.3039 133.878 49.2669 134.036 49.1953C134.195 49.1236 134.337 49.019 134.453 48.8883C134.569 48.7576 134.656 48.6038 134.708 48.4368C134.761 48.2699 134.778 48.0937 134.758 47.9197Z"
      fill="#FFFFFF"
    />
    <path
      d="M137.053 48.6547V45.3865H138.483V48.2135C138.483 48.8944 138.802 49.243 139.349 49.243C139.896 49.243 140.242 48.8944 140.242 48.2135V45.3865H141.671V50.4522H140.242V49.7223C140.083 49.9703 139.865 50.1748 139.608 50.3174C139.351 50.4599 139.063 50.5362 138.769 50.5394C137.687 50.5394 137.053 49.8204 137.053 48.6547Z"
      fill="#FFFFFF"
    />
    <path
      d="M142.326 47.9359C142.31 47.5961 142.363 47.2566 142.48 46.9377C142.598 46.6189 142.779 46.3273 143.011 46.0805C143.244 45.8337 143.524 45.6368 143.834 45.5016C144.144 45.3665 144.479 45.2959 144.817 45.2941C146.501 45.2941 147.275 46.6123 147.275 48.0503C147.275 48.1592 147.275 48.2954 147.275 48.4261H143.766C143.812 48.7167 143.964 48.9797 144.192 49.1632C144.421 49.3467 144.709 49.4374 145.001 49.4175C145.23 49.4228 145.457 49.3794 145.668 49.2902C145.878 49.2011 146.068 49.0681 146.224 48.9L147.042 49.6245C146.693 50.0388 146.226 50.3365 145.705 50.478C145.183 50.6195 144.631 50.5981 144.122 50.4167C143.613 50.2353 143.17 49.9025 142.854 49.4623C142.537 49.0222 142.36 48.4955 142.348 47.9522L142.326 47.9359ZM145.883 47.511C145.883 47.2221 145.769 46.945 145.566 46.7407C145.363 46.5364 145.088 46.4216 144.801 46.4216C144.516 46.4311 144.246 46.549 144.045 46.7512C143.844 46.9535 143.727 47.2251 143.718 47.511H145.883Z"
      fill="#FFFFFF"
    />
    <path
      d="M147.562 49.7718L148.174 48.824C148.631 49.1837 149.186 49.3946 149.766 49.4286C150.177 49.4286 150.366 49.2815 150.366 49.0528C150.366 48.7368 149.879 48.6333 149.322 48.4645C148.618 48.2575 147.816 47.9198 147.816 46.9393C147.816 45.9098 148.645 45.3052 149.663 45.3052C150.331 45.3159 150.983 45.5181 151.541 45.888L151 46.8848C150.591 46.6167 150.122 46.4539 149.636 46.4109C149.289 46.4109 149.094 46.5634 149.094 46.7595C149.094 47.0428 149.576 47.1735 150.123 47.3642C150.827 47.5984 151.644 47.9415 151.644 48.8675C151.644 49.9951 150.805 50.5017 149.717 50.5017C148.939 50.5017 148.182 50.2453 147.562 49.7718Z"
      fill="#FFFFFF"
    />
    <defs>
      <clipPath id="clip0_2392_37284">
        <rect x="8.0647" y="8.06445" width="23.871" height="23.871" rx="11.9355" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default CQDGLogoFull;
